import React from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {useTranslation} from "react-i18next";

import {callApiV2} from '../../../services/server_apiV2';
import {API_URLS, QUERY_KEYS} from '../../../consts/consts';

import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import {notificationError, notificationSuccess} from '../../../utils/utils';

import {Form, Formik} from 'formik';
import {FormikTextField} from '../../../components/formikFields/FormikTextField'
import * as Yup from 'yup';


const LinkingGatewayForm = ({
                                open,
                                openSetter,
                                gateway
                            }) => {
    const {t} = useTranslation()
    const queryClient = useQueryClient();


    const handleFormSubmit = (data) => {
        callApiV2({
            url: API_URLS.GATEWAYS_LINKING(gateway.id),
            method: 'POST',
            data: {is_linked: true, ...data}
        }).then((res) => {
            if (!res.success) {
                notificationError(t(res.short_message, {status: res.status}), res.response);
                return;
            }

            notificationSuccess(t(`Gateway (id: {{id}}) linked successfully`, {id: gateway.id}))

            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.GATEWAYS_UNLINKED]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.GATEWAYS_LINKED]})
            openSetter(false);
        })
    };

    return (
        <Dialog
            open={open}
            onClose={() => openSetter(false)}
            fullWidth={true}
            maxWidth={'md'}
        >
            <DialogTitle>{t('LoRa® Gateway Provisioning')}</DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{id: '', organization: ''}}
                    validationSchema={Yup.object({
                        name: Yup.string().required('Required'),
                        address: Yup.string().required('Required'),
                    })}
                    onSubmit={handleFormSubmit}
                >
                    <Form>
                        <FormikTextField type="text" label={t('Gateway Name')} name="name" size="small"
                                         placeholder={t('Gateway Name, for example: My Gateway')}/>
                        <FormikTextField type="text" label={t('Gateway Address')} name="address" size="small"
                                         placeholder={t('Gateway Address, for example: Germany, Junge Strasse 1B')}/>

                        <DialogActions sx={{p: 0}}>
                            <Button
                                id='link-gw-form-cancel-button'
                                onClick={() => openSetter(false)}
                                disableElevation
                                variant="contained"
                                color="warning"
                            >
                                {t('Close')}
                            </Button>
                            <Button
                                id='link-gw-form-submit-button'
                                type="submit"
                                disableElevation
                                variant="contained"
                                color="secondary"
                            >
                                {t('Link')}
                            </Button>
                        </DialogActions>
                    </Form>
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default LinkingGatewayForm;
