import React from 'react';

import {useQueryClient} from '@tanstack/react-query';
import {API_URLS, QUERY_KEYS} from '../../consts/consts';
import {callApiV2} from '../../services/server_apiV2';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import Typography from "@mui/material/Typography";
import {Stack} from "@mui/material";


const NotificationsHeader = () => {
    const queryClient = useQueryClient();

    const handleMarkAllAsRead = () => {
        return callApiV2({
            url: API_URLS.NOTIFICATIONS + "mark_all_as_read/",
            method: 'post'
        }).then((res) => {
            if (res.success) {
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.NOTIFICATIONS]});
            }
            return res;
        });
    }

    const handleClearAll = () => {
        return callApiV2({
            url: API_URLS.NOTIFICATIONS + "clear_all/",
            method: 'post'
        }).then((res) => {
            if (res.success) {
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.NOTIFICATIONS]});
            }
            return res;
        });
    }

    return (
        <Stack direction="row" justifyContent="space-between" sx={{mb: 2}}>
            <Typography sx={{fontWeight: 600, fontSize: '1.5rem'}}>
                Notifications
            </Typography>
            <Stack direction="row" spacing={1}>
                <Tooltip title="Mark all as read">
                    <IconButton
                        color="primary"
                        id="mark-all-as-read-button"
                        onClick={handleMarkAllAsRead}
                    >
                        <DoneAllIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Clear all">
                    <IconButton
                        color="primary"
                        id="clear-all-button"
                        onClick={handleClearAll}
                    >
                        <ClearAllIcon/>
                    </IconButton>
                </Tooltip>
            </Stack>

        </Stack>
    );
};

export default NotificationsHeader;
