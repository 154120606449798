import {toast} from 'react-toastify';
import SignalEnabled from 'assets/img/icons/signal-enabled.svg';
import SignalDisabled from 'assets/img/icons/signal-disabled.svg';
import SignalNeverSeen from 'assets/img/icons/signal-never-seen.svg';
import {CONNECTION_STATUS_CHOICES} from 'consts/consts';


export const getCountryFlag = (address) => {
    if (address.includes('Germany') || address.includes('Deutschland')) {
        return '🇩🇪 ';
    } else {
        return '🇯🇵 ';
    }
};

export const getMeterTypeIcon = (type) => {
    type = type.toLowerCase().trim();
    switch (type) {
        case 'water':
            return '💧';
        case 'gas':
            return '🔥';
        case 'electricity':
            return '⚡️';

        default:
            return '';
    }
};

export const getMeterTypeNotation = (type) => {
    type = type.toLowerCase().trim();
    switch (type) {
        case 'water':
            return 'LPM';
        case 'gas':
            return 'SCFM';
        case 'electricity':
            return 'kWh';

        default:
            return '';
    }
};

export const roundFloat = (number, decimalsCount = 2) => {
    const factor = Math.pow(10, decimalsCount);
    return Math.round(number * factor) / factor;
};

export function formatDateTime(dateInput) {
    // formats either string or date

    let date = dateInput;
    if (typeof dateInput === 'string') {
        date = new Date(dateInput);
    }

    // Format hours and minutes
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // Format day, month, and year
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${hours}:${minutes}, ${day}.${month}.${year}`;
}

export function formatSecondsInterval(seconds, short = false) {
    if (seconds <= 0) {return ""}

    var levels = [
        [Math.floor(seconds / 31536000), 'years'],
        [Math.floor((seconds % 31536000) / 86400), 'days'],
        [Math.floor(((seconds % 31536000) % 86400) / 3600), 'hours'],
        [Math.floor((((seconds % 31536000) % 86400) % 3600) / 60), 'minutes'],
        [(((seconds % 31536000) % 86400) % 3600) % 60, 'seconds'],
    ];
    if (!short) {
        var returntext = '';

        for (var i = 0, max = levels.length; i < max; i++) {
            if (levels[i][0] === 0) continue;
            returntext += levels[i][0] + ' ' + (levels[i][0] === 1 ? levels[i][1].substr(0, levels[i][1].length - 1) : levels[i][1]) + ", ";
        }
        return returntext.slice(0, -2).trim();
    }

    // Shorten versions
    if (levels[0][0] === 1) {return `more than ${levels[0][0]} year`}
    if (levels[0][0] > 0) {return `more than ${levels[0][0]} years`}

    if (levels[1][0] > 14) {return `more than ${Math.floor(levels[1][0] / 7)} weeks`}
    if (levels[1][0] === 1) {
        if (levels[2][0] === 1) {return `more than ${levels[1][0]} day and ${levels[2][0]} hour`}
        if (levels[2][0] > 0) {return `more than ${levels[1][0]} day and ${levels[2][0]} hours`}
        return `more than ${levels[1][0]} day`
    }
    if (levels[1][0] > 0) {
        if (levels[2][0] === 1) {return `more than ${levels[1][0]} days and ${levels[2][0]} hour`}
        if (levels[2][0] > 0) {return `more than ${levels[1][0]} days and ${levels[2][0]} hours`}
        return `more than ${levels[1][0]} days`
    }

    if (levels[2][0] === 1) {
        if (levels[3][0] === 1) {return `more than ${levels[2][0]} hour and ${levels[3][0]} minute`}
        if (levels[3][0] > 0) {return `more than ${levels[2][0]} hour and ${levels[3][0]} minutes`}
        return `more than ${levels[2][0]} hour`
    }
    if (levels[2][0] > 0) {
        if (levels[3][0] === 1) {return `more than ${levels[2][0]} hours and ${levels[3][0]} minute`}
        if (levels[3][0] > 0) {return `more than ${levels[2][0]} hours and ${levels[3][0]} minutes`}
        return `more than ${levels[2][0]} hours`
    }

    if (levels[3][0] === 1) {return `more than ${levels[3][0]} minute`}
    if (levels[3][0] > 0) {return `more than ${levels[3][0]} minutes`}

    return `less than a minute`
}

export const notificationError = (message, response) => {
    toast.error(<div>
        <div>{message}</div>
        {typeof (response?.data) === "object" && (
            <ul>
                {Object.keys(response.data).map(key => {
                    // skip for auth errors. they should be handled inside call api interceptors (401)
                    if (["code", "detail", "messages"].includes(key)) return;
                    return (<li key={key}>{key} -- {response.data[key][0]}</li>);
                })}
            </ul>
        )}
    </div>);
}

export const notificationSuccess = (text) => {
    toast.success(text);
}

export const notificationWarning = (text) => {
    toast.warn(text);
}

export const statusIconSrc = (status) => {
    switch (status) {
        case CONNECTION_STATUS_CHOICES.ONLINE:
            return SignalEnabled;
        case CONNECTION_STATUS_CHOICES.OFFLINE:
            return SignalDisabled;
        case CONNECTION_STATUS_CHOICES.NEVER_SEEN:
            return SignalNeverSeen;
        default:
            return SignalNeverSeen;
    }
}

export const capitalize = (string) => (
    string.charAt(0).toUpperCase() + string.slice(1)
)
