import { Outlet } from "react-router-dom";
import { CssBaseline } from '@mui/material';
import Divider from '@mui/material/Divider';
import {useTranslation} from "react-i18next";

import {Header} from './Header'
import {Sidebar} from './Sidebar'

import styles from './LayoutStyles.module.css'
import {Footer} from "./Footer";


const Layout = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.root}>
            <CssBaseline />

            <Header />
            <Divider />

            <div className={styles.rootContentContainer}>
                <Sidebar />
                <Divider orientation="vertical" flexItem />
                <div className={styles.outletContainer}>
                    <Outlet />
                </div>
            </div>

            <Divider />
            <Footer />
        </div>
    )
}

export default Layout;
