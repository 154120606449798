import React from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {useTranslation} from "react-i18next";

import {callApiV2} from '../../../services/server_apiV2';
import {API_URLS, QUERY_KEYS} from '../../../consts/consts';

import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import {notificationError, notificationSuccess} from '../../../utils/utils';

import {Form, Formik} from 'formik';
import {FormikTextField} from '../../../components/formikFields/FormikTextField'
import {FormikAutoComplete} from '../../../components/formikFields/FormikAutoComplete'
import * as Yup from 'yup';


const GatewayProvisionForm = ({open, openSetter}) => {
    const {t} = useTranslation()
    const queryClient = useQueryClient();

    const organizationsObjects = queryClient.getQueryData([QUERY_KEYS.ORGANIZATIONS]);

    const onFormSubmit = (data) => {
        const dataRequest = {
            ...data,
            organization: data['organization']?.slug
        };
        callApiV2({url: API_URLS.GATEWAYS, method: 'POST', data: dataRequest}).then((res) => {
            if (!res.success) {
                notificationError(t(res.short_message, {status: res.status}), res.response);
                return;
            }

            notificationSuccess(t("New gateway successfully provisioned"));
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.GATEWAYS_UNLINKED]});
            openSetter(false);
        });
    };

    return (
        <Dialog
            open={open}
            onClose={() => openSetter(false)}
            fullWidth={true}
            maxWidth={'md'}
        >
            <DialogTitle>{t('LoRa® Gateway Registration')}</DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{id: '', organization: ''}}
                    validationSchema={Yup.object({
                        id: Yup.string().required('Required').matches(/^[0-9A-Fa-f]{16}$/, "Should have numbers [0-9] and letters [a-f]. 16 chars only"),
                        organization: Yup.object(),
                    })}
                    onSubmit={onFormSubmit}
                >
                    <Form>
                        <FormikTextField type="text" label={t('Gateway Identity')} name="id" size="small"
                                         placeholder={t('Unique gateway ID, for example: 0102030405060708')}/>
                        <FormikAutoComplete options={organizationsObjects?.map((org) => ({...org, label: org.name}))}
                                            label={t("Organization name")} name="organization" size="small"/>

                        <DialogActions sx={{p: 0}}>
                            <Button
                                id='register-gw-form-cancel-button'
                                onClick={() => openSetter(false)}
                                disableElevation
                                variant="contained"
                                color="warning"
                            >
                                {t('Close')}
                            </Button>
                            <Button
                                id='register-gw-form-submit-button'
                                type="submit"
                                disableElevation
                                variant="contained"
                                color="secondary"
                            >
                                {t('Register Gateway')}
                            </Button>
                        </DialogActions>
                    </Form>
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default GatewayProvisionForm;
