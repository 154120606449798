import {createContext, useContext, useEffect, useState} from 'react';
import {API_URLS, AUTH_TOKEN_URL, QUERY_KEYS} from '../consts/consts';
import i18n from 'i18n';
import {useQuery} from "@tanstack/react-query";
import {callApiV2} from "../services/server_apiV2";

const AuthContext = createContext();

const AuthProvider = ({children}) => {
    const [isAuthed, setIsAuthed] = useState(true);
    const [loginError, setLoginError] = useState('');
    const [isUserCredsLoading, setIsUserCredsLoading] = useState(true);


    const currentUser = useQuery({
        queryKey: [QUERY_KEYS.USERS_ME],
        queryFn: async () =>
            await callApiV2({url: API_URLS.USERS + 'me'}).then((res) =>
                res.success ? res.data : Promise.reject(new Error(res.data))
            ),
        enabled: isAuthed,
        retry: false,
    });


    const verifyToken = async () => {
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');

        setIsUserCredsLoading(true);
        const verifyResponse = await fetch(AUTH_TOKEN_URL.VERIFY, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': i18n.resolvedLanguage,
            },
            body: JSON.stringify({token: accessToken})
        });
        const verifyRes = await verifyResponse.json();

        if (verifyRes.code === 'token_not_valid') {
            const tokenRefreshResponse = await fetch(AUTH_TOKEN_URL.REFRESH, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': i18n.resolvedLanguage,
                },
                body: JSON.stringify({refresh: refreshToken})
            });
            const tokenRefreshRes = await tokenRefreshResponse.json();
            if (tokenRefreshRes.code === 'token_not_valid') {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                setIsAuthed(false);
                return;
            } else {
                localStorage.setItem('accessToken', tokenRefreshRes.access);
                setIsAuthed(true);
                setIsUserCredsLoading(false);
                return;
            }
        }

        setIsUserCredsLoading(false);
    };

    useEffect(() => {
        const token = localStorage.getItem('accessToken')
        if (!token) {
            setIsAuthed(false);
            setIsUserCredsLoading(false);
            return;
        }

        verifyToken();
    }, [isAuthed]);

    const logout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        setIsAuthed(false);
        return true;
    };

    const login = async (data) => {
        try {
            const response = await fetch(AUTH_TOKEN_URL.RETREIVE, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': i18n.resolvedLanguage,
                },
                body: JSON.stringify(data)
            });
            const res = await response.json();
            if (res.access) {
                setLoginError('');
                localStorage.setItem('accessToken', res.access);
                localStorage.setItem('refreshToken', res.refresh);
                setIsAuthed(true);
                return true;
            }

            setLoginError(() => res.detail);
            throw new Error(res.detail);
        } catch (error) {
            console.error('login error', error);
            return error;
        }
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthed,
                isUserCredsLoading,
                loginError,
                setIsAuthed,
                currentUser: currentUser?.data || {},
                login,
                logout,
                verifyToken
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};
