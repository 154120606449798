import React, {useState} from 'react';
import {callApiV2} from '../../../services/server_apiV2';
import {API_URLS, DEFAULT_GW_QUERY_OPTIONS, QUERY_KEYS} from '../../../consts/consts';
import {
    notificationError,
    notificationSuccess,
    notificationWarning,
    capitalize
} from '../../../utils/utils';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {CONNECTION_STATUS_CHOICES} from '../../../consts/consts';
import {useTranslation} from "react-i18next";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Collapse from "@mui/material/Collapse";

import SmartReaderUploadPhotoForm from "../../SmartReadersConsolePage/Forms/SmartReaderUploadPhotoForm";

import {Form, Formik} from 'formik';
import {FormikTextField} from '../../../components/formikFields/FormikTextField'
import {FormikAutoComplete} from '../../../components/formikFields/FormikAutoComplete'
import {FormikSwitch} from '../../../components/formikFields/FormikSwitch'
import * as Yup from 'yup';


export const INTERVAL_OPTIONS = [
    {value: 60 * 5, label: "5 min"},
    {value: 60 * 15, label: "15 min"},
    {value: 60 * 60, label: "1 hour"},
    {value: 3600 * 6, label: "6 hours"},
    {value: 3600 * 24, label: "24 hours"},
    {value: 3600 * 24 * 7, label: "7 days"},
    {value: 3600 * 24 * 30, label: "1 month"},
    {value: 3600 * 24 * 30 * 6, label: "6 months"},
]


const SmartReaderToGatewayLink = ({
                                      open,
                                      openSetter,
                                      gateway,
                                      smartReader
                                  }) => {
    const [isUploadPhotoOpen, setIsUploadPhotoOpen] = useState(false)
    const {t} = useTranslation()
    const queryClient = useQueryClient();

    const {data: smartReadersObjects, isLoading: isSmartMetersLoading} = useQuery({
        queryKey: [QUERY_KEYS.SMART_READERS_UNLINKED],
        queryFn: async () =>
            await callApiV2({url: API_URLS.SMART_READERS, params: {is_linked_to_gw: 'False'}}).then((res) =>
                res.success ? res.data : Promise.reject(new Error(res.data))
            )
    }) || {data: [], isLoading: false};

    const {data: gatewayLinkedObjects, isLoading: isGatewayLinkedLoading} = useQuery({
        queryKey: [QUERY_KEYS.GATEWAYS_LINKED],
        queryFn: async () =>
            await callApiV2({url: API_URLS.GATEWAYS, params: DEFAULT_GW_QUERY_OPTIONS}).then((res) =>
                res.success ? res.data : Promise.reject(new Error(res.data))
            )
    }) || {data: [], isLoading: false};

    const handleFormSubmit = (data) => {
        const dataRequest = {
            ...data,
            gateway: data.gateway.id,
            eui: data.eui.eui,
            meter_type: data.meter_type.value,
            lmn_uplink_interval: data.lmn_uplink_interval.value,
            cloud_uplink_interval: data.cloud_uplink_interval.value,
        }
        callApiV2({url: API_URLS.READERS_COMPLEX_LINK, method: 'POST', data: dataRequest}).then(
            (res) => {
                if (!res.success) {
                    notificationError(t(res.short_message, {status: res.status}), res.response);
                    return;
                }

                if (data.gateway.status.status === CONNECTION_STATUS_CHOICES.ONLINE)
                    notificationSuccess(t(`Smart Reader (id: {{id}}) linked successfully`, {id: dataRequest.eui}));
                else
                    notificationWarning(
                        t(`Smart Reader (id: {{id}}) linked successfully`, {id: dataRequest.eui}) + '\n' +
                        t(`Note: gateway is {{status}}`, {status: capitalize(data.gateway.status.status).split('_').join(' ')})
                    );

                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.GATEWAYS_LINKED]})
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.GATEWAYS_UNLINKED]})
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SMART_READERS_LINKED]});
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SMART_READERS_UNLINKED]});
                openSetter(false);
                setIsUploadPhotoOpen(data.eui);
            }
        );
    };

    const getSROpions = () => {
        return smartReadersObjects
            ? smartReadersObjects.map(sr => ({...sr, label: `EUI: ${sr.eui}`}))
            : []
    }

    const getGWOpions = () => {
        return gatewayLinkedObjects
            ? gatewayLinkedObjects?.map(gw => ({...gw, label: `${gw.name} (${gw.id})`}))
            : []
    }

    const initialValues = {
        meter: '',
        meter_type: '',
        name: '',
        eui: smartReader && {...smartReader, label: `EUI: ${smartReader.eui}`},
        address: '',
        gateway: gateway && {...gateway, label: `${gateway.name} (${gateway.id})`},
        lmn_uplink_interval: INTERVAL_OPTIONS[1],
        cloud_uplink_interval: INTERVAL_OPTIONS[4],
        lmn_mode_enabled: false,
        non_lmn_mode_enabled: true,
    }

    const validationSchema = Yup.object({
        meter: Yup.string().required('Required').max(32, "Number of symbols should be lower than 32"),
        meter_type: Yup.object().required('Required'),
        name: Yup.string().required('Required'),
        eui: Yup.object().required('Required'),
        address: Yup.string().required('Required'),
        gateway: Yup.object().required('Required'),
        lmn_uplink_interval: Yup.object().required('Required'),
        cloud_uplink_interval: Yup.object().required('Required'),
        lmn_mode_enabled: Yup.bool().required('Required'),
        non_lmn_mode_enabled: Yup.bool().required('Required'),
    })

    const typeOptions = [
        {value: 'Electricity', label: t('Electricity')},
        {value: 'Water', label: t('Water')},
        {value: 'Gas', label: t('Gas')}
    ];


    return (
        <>
            <Dialog
                open={open}
                onClose={() => openSetter(false)}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle>{t('Linking Meter to Gateway')}</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                    >
                        {({values}) => (
                            <Form>
                                <FormikTextField type="text" label={t('Meter ID')} name="meter" size="small"
                                                 placeholder={t('Unique Meter ID, for example: 0102030405060708')}/>
                                <FormikAutoComplete options={typeOptions} label={t("Meter Type")} name="meter_type"
                                                    size="small"/>
                                <FormikTextField type="text" label={t('Smart Reader Name')} name="name" size="small"
                                                 placeholder={t('Smart Reader Name, for example: Smart Reader 1')}/>
                                <FormikAutoComplete disabled={Boolean(smartReader)} options={getSROpions()}
                                                    label={t('Smart Reader ID')} name="eui" size="small"
                                                    placeholder={t('Smart Reader Unique ID')}/>
                                <FormikTextField type="text" label={t('Address')} name="address" size="small"
                                                 placeholder={t('Reader physical address, for example: Germany, Junge Strasse 1B')}/>
                                <FormikAutoComplete disabled={Boolean(gateway)} options={getGWOpions()}
                                                    label={t("Gateway")}
                                                    name="gateway" size="small"/>
                                <FormikSwitch label={t("Enable Metering Data via SMGw?")} name="lmn_mode_enabled"/>
                                <Collapse in={values.lmn_mode_enabled}>
                                    <FormikAutoComplete options={INTERVAL_OPTIONS}
                                                        label={t('Metering Data via SMGw Interval')}
                                                        name="lmn_uplink_interval" size="small"
                                                        placeholder={t('Metering Data via SMGw Interval')}/>
                                </Collapse>
                                <FormikSwitch label={t("Enable Metering Data via Cloud?")} name="non_lmn_mode_enabled"/>
                                <Collapse in={values.non_lmn_mode_enabled}>
                                    <FormikAutoComplete options={INTERVAL_OPTIONS}
                                                        label={t('Metering Data via Cloud Interval')}
                                                        name="cloud_uplink_interval" size="small"
                                                        placeholder={t('Metering Data via Cloud Interval')}/>
                                </Collapse>

                                <DialogActions sx={{p: 0}}>
                                    <Button
                                        id="link-meter-to-gw-cancel-button"
                                        onClick={() => openSetter(false)}
                                        disableElevation
                                        variant="contained"
                                        color="warning"
                                    >
                                        {t('Close')}
                                    </Button>
                                    <Button
                                        id="link-meter-to-gw-submit-button"
                                        type="submit"
                                        disableElevation
                                        variant="contained"
                                        color="secondary"
                                    >
                                        {t('Link')}
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>

            {isUploadPhotoOpen && <SmartReaderUploadPhotoForm isOpen={isUploadPhotoOpen} setIsOpen={() => {
                setIsUploadPhotoOpen(false);
                openSetter(false);
            }} smartReader={isUploadPhotoOpen} />}
        </>
    );
};

export default SmartReaderToGatewayLink;
