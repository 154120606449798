import React from 'react';
import { useAuth } from '../../hooks/AuthProvider';
import { Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from '../../consts/routes';
import LoadingScreen from '../LoadingScreen/LoadingScreen';

const PrivateRoute = ({ children }) => {
  const { isAuthed, isUserCredsLoading } = useAuth();
  const location = useLocation();

  if (isUserCredsLoading) return <LoadingScreen />;
  if (!isAuthed) return <Navigate to={ROUTES.LOGIN} replace state={{ from: location.pathname }} />;
  return <>{children}</>;
};

export default PrivateRoute;
