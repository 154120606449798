import {createTheme} from "@mui/material/styles";
import {orange} from '@mui/material/colors';

export const theme = createTheme({
    palette: {
        secondary: {
            main: '#00934d'
        },
        warning: {
            main: '#e6e6e6'
        },
        info: {
            // main: "#3c7eb4"
            // main: '#006710'
            main: '#c05151'
        },
        additional: {
            light: orange["200"],
            main: orange["500"],
            dark: orange["800"],
            contrastText: '#fff',
        },
    },
    typography: {
        fontFamily: ['Roboto Flex', 'Arial'].join(',')
    },
    components: {
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    flexDirection: 'column'
                }
            }
        }
    }
});