import React, {useEffect, useState} from 'react';
import styles from './Login.module.css';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useTranslation} from "react-i18next";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import {API_URLS, AUTH_TOKEN_URL} from "../../consts/consts";
import i18n from "../../i18n";
import {notificationError, notificationSuccess} from "../../utils/utils";
import {ROUTES} from "../../consts/routes";


const ConfirmPasswordReset = () => {
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    const email = searchParams.get("email");
    const code = searchParams.get("code");

    useEffect(() => {
        async function validateLink() {
            if (!email || !code) {
                setError("Bad link");
                return;
            }

            const response = await fetch(API_URLS.USERS + 'validate_password_reset/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': i18n.resolvedLanguage,
                },
                body: JSON.stringify({email, code})
            });
            if (response.status === 200) {
                setLoading(false)
            } else {
                setError("Bad invitation link")
            }
        }

        validateLink();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const formJson = Object.fromEntries(formData.entries());

        if (formJson.password !== formJson.confirm_password) {
            notificationError(t('Passwords mismatch. Abort'));
            return;
        }

        const response = await fetch(API_URLS.USERS + 'password_reset_confirm/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': i18n.resolvedLanguage,
            },
            body: JSON.stringify({...formJson, email, code})
        });
        if (response.status === 200) {
            notificationSuccess("New password created successfully")
            navigate(ROUTES.LOGIN);
        } else {
            notificationError(response.data);
        }

    };

    if (error) return <div style={{textAlign: 'center', fontSize: 'large', fontWeight: 'bold'}}>{error}</div>
    if (loading) return <LoadingScreen/>;

    return (
        <div className={styles.centeredContainer}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t('Set New Password')}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit}>
                        <TextField
                            margin="dense"
                            required
                            fullWidth
                            id="email"
                            label={t("Email")}
                            name="email"
                            value={email}
                            disabled
                        />
                        <TextField
                            margin="dense"
                            required
                            fullWidth
                            name="password"
                            label={t("Password")}
                            type="password"
                            id="password"
                            autoFocus
                        />
                        <TextField
                            margin="dense"
                            required
                            fullWidth
                            name="confirm_password"
                            label={t("Confirm Password")}
                            type="password"
                            id="confirm_password"
                        />
                        <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
                            {t('Change Password')}
                        </Button>
                    </Box>
                </Box>
            </Container>
        </div>
    );
};

export default ConfirmPasswordReset;
