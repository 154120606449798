import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useLocation} from "react-router-dom";
import {useAuth} from 'hooks/AuthProvider';

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Tooltip from '@mui/material/Tooltip';

import {ROUTES} from "../../consts/routes";
import LocationCityIcon from '@mui/icons-material/LocationCity';
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import CastIcon from "@mui/icons-material/Cast";
import DeviceUnknownIcon from "@mui/icons-material/DeviceUnknown";


const SidebarMenuMap = [
    ['Organizations Console', ROUTES.ORGANIZATIONS,
        <LocationCityIcon key={0} color="info"/>, "org-side-button", ["wm", "manufacturer", "utility_tech"]],
    ['LoRa® Gateway Cloud Console', ROUTES.GATEWAYS,
        <SpaceDashboardIcon key={1} color="info"/>, "gw-side-button", ["wm", "manufacturer"]],
    ['LoRa® Smart Readers Cloud Console', ROUTES.END_SMART_READERS,
        <CastIcon key={2} color="info"/>, "sr-side-button", ["wm", "manufacturer"]],
    ['Devices pool', ROUTES.SMART_READER_POOL, <DeviceUnknownIcon key={3} color="info"/>, "pool-side-button"],
];


const SidebarCollapseButton = ({open, onOpen, onClose}) => (
    <IconButton
        sx={{
            position: "absolute",
            top: 42, right: -13, zIndex: 100,
            backgroundColor: "white",
            border: "1px solid rgba(0, 0, 0, 0.12);",
            width: 25,
            height: 25,
            "&:hover": {backgroundColor: "lightgray"},
        }}
        onClick={() => open ? onClose() : onOpen()}
    >
        {open ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
    </IconButton>
)


export const Sidebar = () => {
    const {t} = useTranslation()
    const location = useLocation();
    const {currentUser} = useAuth();
    const [open, setOpen] = useState(true);

    return (
        <List sx={{width: open ? 360 : 60, transition: "width 1s", position: "relative"}}>
            <SidebarCollapseButton
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
            />

            {SidebarMenuMap.map((endpoint, key) => {
                const [buttonText, endpointPath, buttonIcon, idText, userHideArray] = endpoint;

                if (userHideArray && userHideArray.length && userHideArray.includes(currentUser.role)) {
                    return <></>;
                }

                return (
                    <ListItem key={key} disablePadding>
                        <Tooltip title={t(buttonText)} placement="right" disableHoverListener={open}>
                            <ListItemButton
                                id={idText}
                                component={Link}
                                to={endpointPath}
                                selected={location.pathname === endpointPath}
                                style={{textWrap: "nowrap", overflow: "hidden"}}
                            >
                                {buttonIcon}
                                <ListItemText
                                    primary={t(buttonText)}
                                    primaryTypographyProps={{
                                        fontFamily: 'Roboto Flex, sans-serif',
                                        fontWeight: 500,
                                        fontSize: 16,
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginLeft: 1,
                                    }}
                                    style={{
                                        opacity: open ? 1 : 0,
                                        transition: "opacity 1s"
                                    }}
                                />
                            </ListItemButton>
                        </Tooltip>
                    </ListItem>
                );
            })}
        </List>
    );
};