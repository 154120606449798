import React from 'react';
import {useField} from 'formik';
import Switch from "@mui/material/Switch";
import FormControlLabel from '@mui/material/FormControlLabel';


export const FormikSwitch = (props) => {
    const [field, meta] = useField(props);
    return (
        <>
            <FormControlLabel
                style={{marginBottom: 10, width: "100%"}}
                {...props}
                control={<Switch {...field} checked={field.value} />}
            />
        </>
    )
};
