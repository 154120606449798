import React from 'react';
import {useAuth} from '../../hooks/AuthProvider';
import {Navigate} from 'react-router-dom';


const AuthRoute = ({children}) => {
    const {isAuthed} = useAuth();

    if (isAuthed) {
        return <Navigate to="/" replace state={null} />;
    }
    return <>{children}</>;
};

export default AuthRoute;
