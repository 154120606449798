import React from "react";

import {useTranslation} from "react-i18next";
import {useQueryClient} from '@tanstack/react-query';

import {API_URLS, QUERY_KEYS, USER_ROLES_OPTIONS} from '../../../consts/consts';
import {notificationError, notificationSuccess} from '../../../utils/utils';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {callApiV2} from "../../../services/server_apiV2";

import {Form, Formik} from 'formik';
import {FormikTextField} from '../../../components/formikFields/FormikTextField'
import {FormikAutoComplete} from '../../../components/formikFields/FormikAutoComplete'
import * as Yup from 'yup';


const ULILITY_ROLES = ['Utility Admin', 'Utility Tech']

const UserInviteForm = ({organization, handleCloseCallBack}) => {
    const {t} = useTranslation()
    const queryClient = useQueryClient();

    const handleClose = () => {
        handleCloseCallBack && handleCloseCallBack();
    };

    const handleSubmit = (values) => {
        callApiV2({
            url: API_URLS.USERS + `invite/`,
            method: 'post',
            data: {
                ...values,
                organization: organization?.slug,
                role: USER_ROLES_OPTIONS[values.role]
            },
        }).then((res) => {
            if (res.success) {
                notificationSuccess(t(`User ({{email}}) successfully invited`, {email: values.email}));
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.ORGANIZATIONS]});
                handleClose();
                return res;
            } else {
                notificationError(res.response.data?.message || t(res.short_message, {status: res.status}))
            }

        });
    };

    const roleOptions = Object.keys(USER_ROLES_OPTIONS).filter(opt => {
        return organization?.slug ? ULILITY_ROLES.includes(opt) : !ULILITY_ROLES.includes(opt)
    })

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'md'}
        >
            <DialogTitle>{`Invite User to "${organization?.name}" organization`}</DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{email: '', role: ''}}
                    validationSchema={Yup.object({
                        email: Yup.string().email("Invalid email address").required('Required'),
                        role: Yup.string().required("Required")
                    })}
                    onSubmit={handleSubmit}
                >
                    <Form>
                        <FormikTextField type="text" label="User Email" name="email"/>
                        <FormikAutoComplete options={roleOptions} name="role" label="User Role"/>


                        <DialogActions sx={{p: 0}}>
                            <Button
                                id="invite-form-cancel-button"
                                onClick={handleClose}
                                disableElevation
                                variant="contained"
                                color="warning"
                            >
                                Cancel
                            </Button>
                            <Button
                                id="invite-form-submit-button"
                                type="submit"
                                disableElevation
                                variant="contained"
                                color="secondary"
                            >
                                Invite user
                            </Button>
                        </DialogActions>
                    </Form>
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default UserInviteForm;
