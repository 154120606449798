import React, {useState, useRef, useEffect} from 'react';

import {useQuery, useQueryClient} from '@tanstack/react-query';
import {API_URLS, QUERY_KEYS} from '../../consts/consts';
import {callApiV2} from '../../services/server_apiV2';
import {useTranslation} from "react-i18next";
import {useAuth} from "../../hooks/AuthProvider";

import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Paper from "@mui/material/Paper";

import styles from "./Notifications.module.css"
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Stack} from "@mui/material";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import {notificationSuccess} from "../../utils/utils";
import {format} from 'date-fns';
import ClearAllIcon from "@mui/icons-material/ClearAll";


const NotificationMessage = ({notification}) => {
    const queryClient = useQueryClient();

    const handleRead = () => {
        if (notification.read) return;
        return callApiV2({
            url: API_URLS.NOTIFICATIONS + notification.id + "/",
            method: 'patch',
            data: {
                read: true
            }
        }).then((res) => {
            if (res.success) {
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.NOTIFICATIONS]});
            }
            return res;
        });
    }

    const handleClear = () => {
        return callApiV2({
            url: API_URLS.NOTIFICATIONS + notification.id + "/",
            method: 'delete'
        }).then((res) => {
            if (res.success) {
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.NOTIFICATIONS]});
            }
            return res;
        });
    }


    return (
        <Badge color="secondary" variant={notification?.read ? "none" : "dot"}>
            <Paper
                elevation={1}
                className={styles.paperMessage}
                style={{
                    cursor: notification?.read ? undefined : "pointer",
                }}
                onClick={handleRead}
            >
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="column">
                        <Typography
                            sx={{fontWeight: 300, fontSize: '0.7rem'}}
                        >
                            {format(new Date(notification?.message?.created_at), 'MMMM d, yyyy, HH:mm')}
                        </Typography>
                        <Typography>{notification?.message?.text}</Typography>
                    </Stack>

                    {notification?.read && (
                        <Tooltip title="Remove notification">
                            <IconButton
                                color="primary"
                                id="clear-button"
                                className={styles.clearButton}
                                size="small"
                                onClick={handleClear}
                            >
                                <RemoveCircleIcon fontSize="small"/>
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
            </Paper>
        </Badge>
    );
};

export default NotificationMessage;
