import {Avatar, Button, Chip} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";

import styles from "./LayoutStyles.module.css"
import Logo from "../../assets/img/logo.png";
import {useTranslation} from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import i18n from "../../i18n";
import Menu from "@mui/material/Menu";
import {Link} from "react-router-dom";
import {useAuth} from "../../hooks/AuthProvider";
import {useQueryClient} from "@tanstack/react-query";
import Notifications from "../Notifications/Notifications";


function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        style: {
            color: "white",
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}


export const Header = () => {
    const {logout, currentUser} = useAuth();
    const {t} = useTranslation();
    const queryClient = useQueryClient();
    // settings gear
    const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
    const open = Boolean(settingsAnchorEl);

    const [lngAnchorEl, setLngAnchorEl] = useState(null);
    const openLng = Boolean(lngAnchorEl);

    return (
        <header className={styles.headerContainer}>
            <img src={Logo} style={{width: 38, height: 38}}/>
            <div className={styles.titleContainer}>
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: '0.9rem'
                    }}
                >
                    Marchl Metering
                </Typography>

                <Typography
                    sx={{
                        fontSize: '0.9rem'
                    }}
                >
                    {t('Management Console & CMS')}
                </Typography>
            </div>

            <Button
                id="change-language-button"
                size="small"
                sx={{textTransform: "capitalize"}}
                variant="outlined"
                onClick={(event) => setLngAnchorEl(event.currentTarget)}
            >
                {t('Language')}
            </Button>
            <Menu
                anchorEl={lngAnchorEl}
                open={openLng}
                onClose={() => setLngAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
            >
                <MenuItem
                    id="english-menu-item"
                    selected={i18n.resolvedLanguage === "en"}
                    onClick={() => {
                        i18n.changeLanguage("en");
                        setLngAnchorEl(null);
                    }}
                >
                    EN
                </MenuItem>
                <MenuItem
                    id="german-menu-item"
                    selected={i18n.resolvedLanguage === "de"}
                    onClick={() => {
                        i18n.changeLanguage("de");
                        setLngAnchorEl(null)
                    }}
                >
                    DE
                </MenuItem>
            </Menu>

            <Notifications/>

            <Chip
                id="profile-menu-button"
                size="medium"
                avatar={
                    <Avatar {...stringAvatar(`${currentUser.first_name} ${currentUser.last_name}`)}/>
                }
                label={`${currentUser.first_name} ${currentUser.last_name}`}
                onClick={(e) => setSettingsAnchorEl(e.currentTarget)}
            />

            <Menu
                anchorEl={settingsAnchorEl}
                open={open}
                onClose={() => setSettingsAnchorEl(null)}
            >
                <MenuItem id="profile-button" onClick={() => setSettingsAnchorEl(null)} component={Link} to="#">
                    {t('Profile')}
                </MenuItem>
                <MenuItem
                    id="logout-button"
                    onClick={() => {
                        setSettingsAnchorEl(null);
                        queryClient.invalidateQueries()
                        logout();
                    }}
                >
                    {t('Logout')}
                </MenuItem>
            </Menu>
        </header>
    );
};