import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect, useState} from "react";
import {formatDateTime, getMeterTypeIcon} from "../../../utils/utils";
import Chart from "react-apexcharts";

import DE from "apexcharts/dist/locales/de.json"
import EN from "apexcharts/dist/locales/en.json"
import Typography from "@mui/material/Typography";

export const MeasurementsCharts = ({smartReader, measurements}) => {
    const {t, i18n} = useTranslation()
    const [chartData, setChartData] = useState(null);
    const chartSeries = {
        currentConsumption: t("Current Consumption"),
        currentProduction: t("Current Production"),
        totalConsumption: t("Total Consumption"),
        totalProduction: t("Total Production"),
    }

    useEffect(() => {
        if (!measurements.length) return;

        // for optimization process we make dots no more than 200 by skipping them
        let redusedMeasurements = []
        if (measurements.length > 200) {
            const step = Math.floor(measurements.length / 200)
            redusedMeasurements = measurements.filter((item, index) => index % step === 0)
        } else {
            redusedMeasurements = measurements
        }

        // for apexcharts - https://apexcharts.com/react-chart-demos/line-charts/
        setChartData({
            series: [
                {
                    name: chartSeries.currentConsumption,
                    data: redusedMeasurements.map((meas) => meas.current_consumption / 1000)
                },
                {
                    name: chartSeries.currentProduction,
                    data: redusedMeasurements.map((meas) => meas.current_production / 1000)
                },
                {
                    name: chartSeries.totalConsumption,
                    data: redusedMeasurements.map((meas) => meas.total_consumption / 1000)
                },
                {
                    name: chartSeries.totalProduction,
                    data: redusedMeasurements.map((meas) => meas.total_production / 1000)
                },
            ],
            options: {
                chart: {
                    locales: [DE, EN],
                    defaultLocale: i18n.resolvedLanguage,
                    height: 350,
                    type: 'line',
                    // zoom: {
                    //   enabled: false
                    // }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    text: t('Measurements over time'),
                    align: 'left'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: redusedMeasurements.map((meas) => formatDateTime(meas.time)),
                }
            },

        });
    }, [measurements]);

    const hideSeriesOnInit = (node) => {
        node?.chart?.hideSeries(chartSeries.currentConsumption);
        node?.chart?.hideSeries(chartSeries.currentProduction);
        node?.chart?.hideSeries(chartSeries.totalProduction);
    }

    const chartRef = useCallback(node => {
        if (node !== null) {
            setTimeout(() => hideSeriesOnInit(node), 100)
        }
    }, []);

    if (!measurements.length) return;
    return (
        <>
            <Typography sx={{
                fontWeight: 500,
                fontSize: '1.1rem',
                marginTop: 1.2,
                textAlign: "center"
            }}>
                {getMeterTypeIcon(smartReader.meter.meter_type)} {t(smartReader.meter.meter_type)} {t('meter consumption / production over time')}
            </Typography>
            <Typography sx={{
                fontWeight: 400,
                color: 'text.secondary',
                textAlign: "center"
            }}>
                {t('(click on labels below to hide / show chart series)')}
            </Typography>

            {chartData &&
                <Chart
                    ref={chartRef}
                    options={chartData.options}
                    series={chartData.series}
                    type="line"
                />
            }
        </>
    );
};