import React, {useState} from 'react';
import styles from './Login.module.css';
import {useAuth} from 'hooks/AuthProvider';
import {ROUTES} from 'consts/routes';
import {Link} from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {notificationError} from 'utils/utils';
import {useTranslation} from "react-i18next";


const Login = () => {
    const { t } = useTranslation()
    const [input, setInput] = useState({
        email: '',
        password: ''
    });

    const {login, loginError} = useAuth();
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (input.username !== '' && input.password !== '') {
            login(input);
            return;
        }
        notificationError(t('Provide a valid credentials'))
    };

    const handleInput = (e) => {
        const {name, value} = e.target;
        setInput((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    return (
        <div className={styles.centeredContainer}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t('Sign in')}
                    </Typography>
                    {loginError && <p className={styles.loginError}>{loginError}</p>}
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={t("Email")}
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={handleInput}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={t("Password")}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={handleInput}
                        />
                        <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
                            {t('Sign in')}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link to={"/forgot_password"}>
                                    {t('Forgot password?')}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </div>
    );
};

export default Login;
