import { ToastContainer as Container } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ToastContainer = () => {
    // Editor - https://fkhadra.github.io/react-toastify/introduction/
    return (
        <Container
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
    )
}

export default ToastContainer
