import React from 'react';
import {useField} from 'formik';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";


export const FormikAutoComplete = ({label, lookupField, ...props}) => {
    const [field, meta, helpers] = useField(props);
    const {options, disabled, ...otherProps} = props;

    return (
        <>
            <Autocomplete
                options={options}
                disabled={disabled}
                onChange={(event, newValue) => {
                    helpers.setValue(newValue, true)
                }}
                value={field.value}
                margin="dense"
                getOptionLabel={(option) => lookupField && option[lookupField] ? option[lookupField] : option.label ?? option.name ?? option}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        {...field}
                        {...otherProps}
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        label={label}
                        error={Boolean(meta.touched && meta.error)}
                        helperText={(meta.touched && meta.error) || " "}
                    />
                )}
            />
        </>
    );
};
