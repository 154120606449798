import React from 'react';
import Typography from "@mui/material/Typography";


const FieldTypography = ({value, label}) => {
    return (
        <Typography variant="subtitle2">
            <span style={{
                fontStyle: "italic",
                marginRight: 10
            }}>{label}: </span>{value}
        </Typography>
    );
};

export default FieldTypography;
