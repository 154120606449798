import {useQuery, useQueryClient} from "@tanstack/react-query";
import {notificationError, notificationSuccess} from "../../../utils/utils";
import {API_URLS, DEFAULT_GW_QUERY_OPTIONS, QUERY_KEYS} from "../../../consts/consts";
import React from "react";
import {useTranslation} from "react-i18next";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import {Form, Formik} from 'formik';
import {FormikTextField} from '../../../components/formikFields/FormikTextField'
import {FormikAutoComplete} from '../../../components/formikFields/FormikAutoComplete'
import * as Yup from 'yup';
import {callApiV2} from "../../../services/server_apiV2";
import {useAuth} from "../../../hooks/AuthProvider";


const GatewayEditForm = ({isOpen, setIsOpen, gateway}) => {
    const {t} = useTranslation()
    const queryClient = useQueryClient();
    const {currentUser} = useAuth();

    if (!gateway) return;

    const orgQuery = useQuery({
        queryKey: [QUERY_KEYS.ORGANIZATIONS],
        queryFn: async () =>
            await callApiV2({url: API_URLS.ORGANIZATIONS}).then((res) =>
                res.success ? res.data : Promise.reject(new Error(res.data))
            )
    });
    const organizationsObjects = orgQuery.data || [];


    const handleFormSubmit = (values) => {
        const dataRequest = {
            ...values,
            organization: values.organization.slug,
            id: values.id
        };
        callApiV2({url: API_URLS.GATEWAYS + dataRequest.id + '/', method: 'PATCH', data: dataRequest}).then(
            (res) => {
                if (!res.success) {
                    notificationError(t(res.short_message, {status: res.status}), res.response);
                    return;
                }
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.GATEWAYS, DEFAULT_GW_QUERY_OPTIONS]});
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.GATEWAYS_UNLINKED]})
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.GATEWAYS_LINKED]})

                notificationSuccess(t("Gateway edited successfully"));
                setIsOpen(false);
                return res;
            }
        );
    };

    const handleRebootGW = gateway => {
        callApiV2({
                url: `${API_URLS.GATEWAYS}${gateway.id}/reboot/`,
                method: 'POST',
            }).then(
                (res) => {
                    if (!res.success) {
                        notificationError(t(res.short_message, {status: res.status}), res.response);
                        return;
                    }
                    notificationSuccess(t("Gateway will be rebooted soon"));
                }
            );
    }

    const validationSchema = Yup.object({
        name: gateway.is_linked ? Yup.string().required('Required') : undefined,
        id: Yup.string().required('Required'),
        organization: Yup.object().required('Required'),
        address: gateway.is_linked ? Yup.string().required('Required') : undefined,
        gw_sync_interval: gateway.is_linked ? Yup.number().required('Required').min(5, "Should be greater than 5") : undefined,
        metering_report_interval: gateway.is_linked ? Yup.number().required('Required').min(5, "Should be greater than 5") : undefined,
    })


    return (
        <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            fullWidth={true}
            maxWidth={'md'}
        >
            <DialogTitle>{t('Gateway Edit Form')}</DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={gateway}
                    validationSchema={validationSchema}
                    onSubmit={handleFormSubmit}
                >
                    <Form>
                        {gateway.is_linked && (
                            <FormikTextField type="text" label={t('Name')} name="name" size="small"
                                             placeholder={t('Name')}/>
                        )}
                        <FormikAutoComplete
                            disabled={!(["wm", "superuser"].includes(currentUser.role) && !gateway.organization)}
                            options={organizationsObjects.map((org) => ({...org, label: org.name}))}
                            label={t("Organization name")} name="organization" size="small"/>
                        {gateway.is_linked && (
                            <FormikTextField type="text" label={t('Gateway address')} name="address" size="small"
                                             placeholder={t('Gateway address, for example: Germany, Junge Strasse 1B')}/>
                        )}
                        {gateway.is_linked && (
                            <FormikTextField type="number" label={t('Gateway synchronization interval (seconds)')}
                                             name="gw_sync_interval" size="small"
                                             placeholder={t('Gateway synchronization interval(seconds), for example: 30')}/>
                        )}
                        {gateway.is_linked && (
                            <FormikTextField type="number" label={t('Metering report interval (seconds)')}
                                             name="metering_report_interval" size="small"
                                             placeholder={t('Metering report interval(seconds), for example: 30')}/>
                        )}
                        <FormikTextField disabled type="text" label={t('Unique gateway ID')} name="id" size="small"
                                         placeholder={t('Unique gateway ID, for example: 0102030405060708')}/>

                        <DialogActions sx={{p: 0}} style={{justifyContent: "space-between"}}>
                            <div style={{display: "flex", gap: 10}}>
                                {/*TODO: uncomment when ready on GW*/}
                                {/*{gateway.is_linked && (*/}
                                {/*    <Button*/}
                                {/*        id="edit-gw-form-reboot-button"*/}
                                {/*        onClick={() => handleRebootGW(gateway)}*/}
                                {/*        disableElevation*/}
                                {/*        variant="contained"*/}
                                {/*        color="additional"*/}
                                {/*    >*/}
                                {/*        {t('Reboot')}*/}
                                {/*    </Button>*/}
                                {/*)}*/}
                            </div>
                            <div style={{display: "flex", gap: 10}}>
                                <Button
                                    id="edit-gw-close-button"
                                    onClick={() => setIsOpen(false)}
                                    disableElevation
                                    variant="contained"
                                    color="warning"
                                >
                                    {t('Close')}
                                </Button>
                                <Button
                                    id="edit-gw-submit-button"
                                    type="submit"
                                    disableElevation
                                    variant="contained"
                                    color="secondary"
                                >
                                    {t('Save')}
                                </Button>
                            </div>
                        </DialogActions>
                    </Form>
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default GatewayEditForm
