import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import PrivateRoute from './components/helpers/PrivateRoute';
import AuthRoute from './components/helpers/AuthRoute';
import './App.css';
import Layout from 'components/Layout/Layout'
import ToastContainer from 'components/ToastContainer/ToastContainer';

import {theme} from "./theme";

// MUI5
import {ThemeProvider} from '@mui/material/styles';

// RSuite
import 'rsuite/dist/rsuite.min.css';

// Pages
import GatewayConsole from './pages/GatewaysConsolePage/GatewayConsole';
import OrganizationsConsole from './pages/OrganizationsPage/OrganizationsConsole';
import DevicePool from 'pages/DevicePoolPage/DevicePool';
import SmartReadersConsole from './pages/SmartReadersConsolePage/SmartReadersConsole';
import Login from './pages/Login/Login';
import ConfirmInvite from './pages/Login/ConfirmInvite';
import ForgotPasswordRequest from "./pages/Login/ForgotPasswordRequest";
import ConfirmPasswordReset from "./pages/Login/ConfirmPasswordReset";

// Others
import {ROUTES} from './consts/routes';


const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <Routes>
                {/* Auth */}
                <Route
                    path={ROUTES.LOGIN}
                    exact
                    element={
                        <AuthRoute>
                            <Login/>
                        </AuthRoute>
                    }
                />
                <Route path={ROUTES.CONFIRM_INVITE} exact element={<ConfirmInvite/>}/>
                <Route path={ROUTES.FORGOT_PASSWORD} exact element={<ForgotPasswordRequest/>}/>
                <Route path={ROUTES.FORGOT_PASSWORD_CONFIRM} exact element={<ConfirmPasswordReset/>}/>

                {/* Console Dashboards */}
                <Route element={<Layout/>}>
                    <Route
                        path={ROUTES.ORGANIZATIONS}
                        exact
                        element={
                            <PrivateRoute>
                                <OrganizationsConsole/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={ROUTES.GATEWAYS}
                        exact
                        element={
                            <PrivateRoute>
                                <GatewayConsole/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={ROUTES.END_SMART_READERS}
                        exact
                        element={
                            <PrivateRoute>
                                <SmartReadersConsole/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={ROUTES.SMART_READER_POOL}
                        exact
                        element={
                            <PrivateRoute>
                                <DevicePool/>
                            </PrivateRoute>
                        }
                    />
                </Route>

                {/* Others */}
                <Route path="*" element={<Navigate to={{pathname: ROUTES.GATEWAYS}}/>}/>
            </Routes>
            <ToastContainer/>
        </ThemeProvider>
    );
};

export default App;
