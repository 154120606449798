import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

import {callApiV2} from '../../../services/server_apiV2';
import {API_URLS} from '../../../consts/consts';

import {DateRangePicker, CustomProvider} from 'rsuite';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";

import {MeasurementsTableData} from "./MeasurementsTableData";
import {MeasurementsCharts} from "./MeasurementsCharts";

import deDE from 'rsuite/locales/de_DE'

import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import addMonths from 'date-fns/addMonths';

const {afterToday} = DateRangePicker;
const getPredefinedRanges = (t) => [
    {
        label: t('Today'),
        value: [startOfDay(new Date()), endOfDay(new Date())],
        placement: 'left'
    },
    {
        label: t('Yesterday'),
        value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))],
        placement: 'left'
    },
    {
        label: t('Last 7 days'),
        value: [subDays(new Date(), 6), new Date()],
        placement: 'left'
    },
    {
        label: t('Last 30 days'),
        value: [subDays(new Date(), 29), new Date()],
        placement: 'left'
    },
    {
        label: t('This month'),
        value: [startOfMonth(new Date()), new Date()],
        placement: 'left'
    },
    {
        label: t('Last month'),
        value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
        placement: 'left'
    },
    {
        label: t('Last 3 months'),
        value: [startOfMonth(addMonths(new Date(), -3)), endOfMonth(addMonths(new Date(), -1))],
        placement: 'left'
    },
];


const PopUpSmartReaderInfo = ({open, openSetter, smartReader}) => {
    const {t, i18n} = useTranslation()
    const [measurements, setMeasurements] = useState([]);
    const [dateRange, setDateRange] = useState([subDays(new Date(), 6), new Date()]);


    useEffect(() => {
        if (!dateRange) return;

        callApiV2({
            url: API_URLS.METERING, method: 'post', data: {
                "meter_id": smartReader.meter.id,
                "from_date": startOfDay(dateRange[0]),
                "to_date": endOfDay(dateRange[1]),
            }
        }).then(
            (res) => {
                if (res.success) {
                    setMeasurements(res.data.reverse())
                } else return Promise.reject(new Error(res.data));
            }
        )
    }, [dateRange, smartReader]);


    return (
        <Dialog
            open={open}
            onClose={() => openSetter(false)}
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle>{t('Meter State')}</DialogTitle>
            <DialogContent>
                <Box display="flex" justifyContent="space-between" sx={{mb: 2}}>
                    <CustomProvider locale={i18n.resolvedLanguage === 'de' ? deDE : undefined}>
                        <DateRangePicker
                            ranges={getPredefinedRanges(t)}
                            shouldDisableDate={afterToday()}
                            isoWeek
                            // defaultValue={[subDays(new Date(), 6), new Date()]}
                            value={dateRange}
                            onChange={setDateRange}
                            placeholder={t("Select date range")}
                        />
                    </CustomProvider>
                </Box>

                <MeasurementsTableData smartReader={smartReader} measurements={measurements}/>
                <MeasurementsCharts smartReader={smartReader} measurements={measurements}/>

            </DialogContent>
            <DialogActions>
                <Button
                    id="show-sr-data-close-button"
                    onClick={() => openSetter(false)}
                    disableElevation
                    variant="contained"
                    color="warning"
                >
                    {t('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PopUpSmartReaderInfo;
