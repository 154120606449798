import React, {useEffect, useMemo, useState} from 'react';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import _ from "lodash";

import {callApiV2} from '../../services/server_apiV2';
import {API_URLS, DEFAULT_SMART_READER_QUERY_OPTIONS, QUERY_KEYS} from '../../consts/consts';
import {
    formatSecondsInterval,
    notificationError,
    notificationSuccess, notificationWarning,
} from '../../utils/utils';

import {IconButton, Typography} from "@mui/material";
import PopUpMeterReaderInfo from "./SmartReaderDetails/PopUpMeterReaderInfo";
import ConnectionStatus from '../../components/ConnectionStatus/ConnectionStatus';
import SmartReaderTypeIcon from "../../components/SmartReaderTypeIcon/SmartReaderTypeIcon";
import SmartReaderApprovedStatus from "../../components/SmartReaderApprovedStatus/SmartReaderApprovedStatus";

import Tooltip from "@mui/material/Tooltip";
import Box from '@mui/material/Box';
import EditIcon from "@mui/icons-material/Edit";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CancelIcon from '@mui/icons-material/Cancel';

import SmartReaderEditForm from "./Forms/SmartReaderEditForm";
import SmartReaderActivationForm from "./Forms/SmartReaderActivationForm";
import SmartReaderUploadPhotoForm from "./Forms/SmartReaderUploadPhotoForm";
import SmartReaderFirmwareStatus from "../../components/SmartReaderFirmwareStatus/SmartReaderFirmwareStatus";

import {useAuth} from "../../hooks/AuthProvider";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {format} from "date-fns";
import {USER_HOME_ROUTES} from "../../consts/routes";

import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Grid from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Checkbox from "@mui/material/Checkbox";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchBar from "@mkyy/mui-search-bar";


// Should be moved to separate component
const SortableTableCell = ({label, field, order, orderBy, onSort}) => {
    return (
        <TableCell>
            <TableSortLabel
                active={field === orderBy}
                direction={orderBy === field ? order : 'asc'}
                onClick={() => onSort && onSort(field)}
            >
                {label}
            </TableSortLabel>
        </TableCell>
    )
}

function descendingComparator(a, b, orderBy) {
    if (_.get(b, orderBy, 0) < _.get(a, orderBy, 0)) {
        return -1;
    }
    if (_.get(b, orderBy, 0) > _.get(a, orderBy, 0)) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


const SmartReadersConsole = () => {
    const {t, i18n} = useTranslation();
    const {currentUser} = useAuth();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [isOTAUpdateMode, setIsOTAUpdateMode] = useState(false);
    const [selectedIds, setSelectedIds] = React.useState([]);
    const [selectedFirmware, setSelectedFirmware] = React.useState(null);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState(null);

    const [openCollapse, setOpenCollapse] = React.useState(null);

    const [displaySmartReaderPopUp, setDisplaySmartReaderPopUp] = useState(false);
    const [currentSelectedSmartReader, setCurrentSelectedSmartReader] = useState();
    const [queryOptions, setQueryOptions] = useState({...DEFAULT_SMART_READER_QUERY_OPTIONS});
    const [isPopUpReaderEditOpen, setIsPopUpReaderEditOpen] = useState(false);
    const [isPopUpReaderActivationOpen, setIsPopUpReaderActivationOpen] = useState(false);
    const [isPopUpUploadPhotoOpen, setIsPopUpUploadPhotoOpen] = useState(false);

    const [searchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState(searchParams ? searchParams.get('search') : '');

    const {data: smartReadersObjects, isLoading: isSmartReadersLoading} = useQuery({
        queryKey: [QUERY_KEYS.SMART_READERS_LINKED],
        queryFn: async () =>
            await callApiV2({url: API_URLS.SMART_READERS, params: queryOptions}).then((res) =>
                res.success ? res.data : Promise.reject(new Error(res.data))
            ),
        refetchInterval: 1000 * 60 * 5,
    });

    const firmwareSRQuery = useQuery({
        queryKey: [QUERY_KEYS.SR_FIRMWARE_VERSIONS],
        queryFn: async () =>
            await callApiV2({url: API_URLS.SR_FIRMWARE_VERSIONS}).then((res) =>
                res.success ? res.data : Promise.reject(new Error(res.data))
            ),
        enabled: isOTAUpdateMode,
    });
    const firmwareVersionObjects = firmwareSRQuery.data || [];

    const handleCloseUpdateMode = () => {
        setIsOTAUpdateMode(false);
        setSelectedIds([]);
    }

    const handleOTAUpdate = () => {
        if (!selectedFirmware) {
            notificationError("Select firmware version first");
            return;
        }

        if (!selectedIds.length) {
            notificationError("Select at least one SR from the list below");
            return;
        }

        const groupedSR = selectedIds.map(eui => smartReadersObjects.find(sr => sr.eui === eui)).reduce((acc, current) => {
            const gw = current.gateway
            if (!acc[gw]) {
                acc[gw] = []
            }
            acc[gw] = [...acc[gw], current.eui]
            return acc
        }, {})

        const data = {
            fw_version: selectedFirmware,
            smart_readers: groupedSR
        }

        callApiV2({
            url: API_URLS.SMART_READERS + "ota_update/",
            method: 'POST',
            data
        }).then(
            (res) => {
                if (!res.success) {
                    if (res.status === 409) {
                        notificationWarning(t(res.response.data.message));
                        queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SMART_READERS_LINKED]});
                        queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SMART_READERS_UNLINKED]});
                        handleCloseUpdateMode();
                        return;
                    }

                    notificationError(t(res.short_message, {status: res.status}), res.response);
                    return;
                }
                notificationSuccess(t("Smart Reader will be upgraded soon"));
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SMART_READERS_LINKED]});
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SMART_READERS_UNLINKED]});
                handleCloseUpdateMode();
            }
        );
    }

    const isDataButtonDisabled = ['utility_tech', 'wm'].includes(currentUser.role);

    if (['wm', 'manufacturer'].includes(currentUser.role)) {
        USER_HOME_ROUTES[currentUser.role] && navigate(USER_HOME_ROUTES[currentUser.role]);
        return (
            <div>
                <div>You don't have access to this page.</div>
            </div>
        )
    }

    // Helper function to flatten the object and search for a query
    function flattenObject(obj, prefix = '') {
        let result = {};
        for (const key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                Object.assign(result, flattenObject(obj[key], `${prefix}${key}.`));
            } else {
                result[`${prefix}${key}`] = obj[key];
            }
        }
        return result;
    }

    function isRowSelectable(sr) {
        if (sr.fw_version === "N/A" || sr.fw_version === "0.0.0.0"
            || sr.connection_status === "never_seen") return false;

        if (sr.fw_version !== sr.desired_fw_version) return false;

        return selectedFirmware && sr.fw_version < selectedFirmware.version
    }

    const onSort = (field) => {
        const isAsc = orderBy === field && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(field);
    }

    const filteredSRObjects = useMemo(
        () => smartReadersObjects
            ?.filter(item => {
                if (!searchQuery) return true;

                const flatItem = flattenObject(item);
                return Object.values(flatItem).some(value =>
                    value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
                );
            })
            .sort(getComparator(order, orderBy)),
        [smartReadersObjects, searchQuery, order, orderBy]
    )

    return (
        <>
            <Box style={{display: "flex", justifyContent: "space-between"}}>
                <SearchBar
                    style={{border: '1px solid #1976d2', alignSelf: 'end'}}
                    value={searchQuery}
                    onChange={newValue => setSearchQuery(newValue)}
                    onSearch={() => {
                    }}
                    onCancelResearch={() => setSearchQuery('')}
                />
                {!['helpdesk'].includes(currentUser.role) && !isOTAUpdateMode && (<Button
                    id="update-firmware-sr-button"
                    color="secondary"
                    variant="contained"
                    onClick={() => setIsOTAUpdateMode(true)}
                    style={{margin: '0 0 0 auto', display: 'block', textTransform: "capitalize"}}
                >
                    Update Firmware
                </Button>)}
                {isOTAUpdateMode && (
                    <Box style={{display: "flex", justifyContent: "flex-end", gap: 10, alignItems: "center"}}>
                        <div>
                            <Tooltip title={t("Cancel")}>
                                <IconButton
                                    id="cancel-ota-sr-button"
                                    onClick={handleCloseUpdateMode}
                                    style={{color: "red"}}
                                >
                                    <CancelIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t("Update SR's firmware")}>
                                <IconButton
                                    id="submit-ota-sr-button"
                                    onClick={handleOTAUpdate}
                                    style={{color: "green"}}
                                >
                                    <CheckCircleIcon/>
                                </IconButton>
                            </Tooltip>
                        </div>

                        <Autocomplete
                            style={{minWidth: 250}}
                            options={firmwareVersionObjects.sort((a, b) => b.version.localeCompare(a.version))}
                            onChange={(event, newValue) => {
                                setSelectedFirmware(newValue);
                                setSelectedIds([])
                            }}
                            value={selectedFirmware}
                            size="small"
                            margin="dense"
                            getOptionLabel={(opt) => opt.version}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    margin="dense"
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Select Firmware"
                                />
                            )}
                        />
                    </Box>
                )}
            </Box>


            <TableContainer sx={{mt: 2}} component={Paper}>
                <Table aria-label="collapsible table" size={"small"}>
                    <TableHead>
                        <TableRow>
                            {isOTAUpdateMode && <TableCell>
                                <Checkbox
                                    size={"small"}
                                    checked={selectedIds.some(item => item)}
                                    indeterminate={selectedIds.length !== filteredSRObjects?.length && selectedIds.some(item => item)}
                                    onChange={() => {
                                        const selectableSR = filteredSRObjects.filter(sr => isRowSelectable(sr))
                                        if (selectedIds.length !== selectableSR?.length) {
                                            setSelectedIds(selectableSR.map(sr => sr.eui))
                                        } else {
                                            setSelectedIds([])
                                        }
                                    }}
                                />
                            </TableCell>}

                            <TableCell/>
                            <SortableTableCell label="Approved" field={"activated"} onSort={onSort} order={order}
                                               orderBy={orderBy}/>
                            <SortableTableCell label="Name" field={"name"} onSort={onSort} order={order}
                                               orderBy={orderBy}/>
                            <SortableTableCell label="Type" field={"meter.meter_type"} onSort={onSort} order={order}
                                               orderBy={orderBy}/>
                            <SortableTableCell label="State" field={"state.status"} onSort={onSort} order={order}
                                               orderBy={orderBy}/>
                            <SortableTableCell label="Organization" field={"organization.name"} onSort={onSort}
                                               order={order}
                                               orderBy={orderBy}/>
                            <SortableTableCell label="Gateway" field={"gateway_name"} onSort={onSort} order={order}
                                               orderBy={orderBy}/>
                            <TableCell>Actions</TableCell>
                            <SortableTableCell label="FW version" field={"fw_version"} onSort={onSort} order={order}
                                               orderBy={orderBy}/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredSRObjects?.length ? filteredSRObjects.map((row) => (
                            <React.Fragment key={row.eui}>
                                <TableRow sx={{
                                    '& > *': {borderBottom: 'unset'},
                                    backgroundColor: !row.activated && "rgb(255, 235, 175)"
                                }}>
                                    {isOTAUpdateMode && <TableCell>
                                        <Checkbox
                                            size={"small"}
                                            disabled={!isRowSelectable(row)}
                                            checked={selectedIds.includes(row.eui)}
                                            onChange={() => {
                                                setSelectedIds(prev => {
                                                    if (prev.includes(row.eui)) {
                                                        return prev.filter(eui => eui !== row.eui)
                                                    }
                                                    return [...prev, row.eui]
                                                })
                                            }}
                                        />
                                    </TableCell>}

                                    <TableCell>
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => setOpenCollapse(prev => prev === row.eui ? null : row.eui)}
                                        >
                                            {openCollapse === row.eui ? <KeyboardArrowUpIcon/> :
                                                <KeyboardArrowDownIcon/>}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell><SmartReaderApprovedStatus smartReader={row}/></TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell><SmartReaderTypeIcon type={row.meter?.meter_type}/></TableCell>
                                    <TableCell><ConnectionStatus status={row.state?.status}/></TableCell>
                                    <TableCell>{row.organization?.name}</TableCell>
                                    <TableCell>{row.gateway_name}</TableCell>
                                    <TableCell>
                                        <>
                                            <Tooltip title={t("Show Smart Reader Data")}>
                                                <IconButton
                                                    id="show-sr-data-button"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setDisplaySmartReaderPopUp(true);
                                                        setCurrentSelectedSmartReader(row);
                                                    }}
                                                    style={{color: isDataButtonDisabled ? "grey" : "green"}}
                                                    disabled={isDataButtonDisabled}
                                                >
                                                    <QueryStatsIcon/>
                                                </IconButton>
                                            </Tooltip>
                                            {!['helpdesk'].includes(currentUser.role) && (
                                                <Tooltip title={t("Edit Smart Reader")}>
                                                    <IconButton
                                                        id="edit-sr-button"
                                                        onClick={() => setIsPopUpReaderEditOpen(row)}
                                                        style={{color: "orange"}}
                                                    >
                                                        <EditIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            {['superuser', 'utility_admin'].includes(currentUser.role) && row.activated === false && (
                                                <Tooltip title={t("Approve Smart Reader")}>
                                                    <IconButton
                                                        disabled={row.state.status === "never_seen"}
                                                        id="activate-sr-button"
                                                        onClick={() => setIsPopUpReaderActivationOpen(row)}
                                                        style={{color: row.state.status === "never_seen" ? "grey" : "blue"}}
                                                    >
                                                        <CheckCircleIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            {['superuser', 'utility_tech'].includes(currentUser.role) && row.activated === false && (
                                                <Tooltip title={t("Upload photo")}>
                                                    <IconButton
                                                        id="upload-photo-activate-sr-button"
                                                        onClick={() => setIsPopUpUploadPhotoOpen(row)}
                                                        style={{color: "blue"}}
                                                    >
                                                        <FileUploadIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </>
                                    </TableCell>
                                    <TableCell><SmartReaderFirmwareStatus smart_reader={row}/></TableCell>
                                </TableRow>
                                <TableRow sx={{backgroundColor: !row.activated && "rgb(255, 235, 175)"}}>
                                    <TableCell style={{paddingBottom: 0, paddingTop: 0}}
                                               colSpan={isOTAUpdateMode ? 10 : 9}>
                                        <Collapse in={openCollapse === row.eui} timeout="auto" unmountOnExit>
                                            <Grid container>
                                                <Grid item xs={6} style={{padding: 10}}>
                                                    <Typography variant="subtitle2">
                                                        <span style={{fontStyle: "italic", marginRight: 10}}>
                                                            {t('Meter ID')}: </span>
                                                        {row.meter.id || "N/A"}
                                                    </Typography>
                                                    <Typography variant="subtitle2">
                                                        <span style={{fontStyle: "italic", marginRight: 10}}>
                                                            {t('EUI')}: </span>
                                                        {row.eui || "N/A"}
                                                    </Typography>

                                                    <Typography variant="subtitle2">
                                                        <span style={{fontStyle: "italic", marginRight: 10}}>
                                                            {t('Last Seen')}: </span>
                                                        {row.last_seen ? format(new Date(row.last_seen * 1000), 'yyyy-MM-dd HH:mm:ss') : "N/A"}
                                                    </Typography>
                                                    <Typography variant="subtitle2">
                                                        <span style={{fontStyle: "italic", marginRight: 10}}>
                                                            {t('Latest firmware update')}: </span>
                                                        {row.ota_status.last_firmware_updated ? format(new Date(row.ota_status.last_firmware_updated), 'yyyy-MM-dd HH:mm:ss') : "N/A"}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={6} style={{padding: 10}}>
                                                    <Typography variant="subtitle2">
                                                        <span style={{fontStyle: "italic", marginRight: 10}}>
                                                            {t('Address')}: </span>
                                                        {row.address || "N/A"}
                                                    </Typography>
                                                    <Typography variant="subtitle2">
                                                        <span style={{fontStyle: "italic", marginRight: 10}}>
                                                            {t('Battery Level')}: </span>
                                                        {typeof (row.battery_level) === "number" ? row.battery_level === -1 ? "DC" : row.battery_level + " %" : "N/A"}
                                                    </Typography>
                                                    <Typography variant="subtitle2">
                                                        <span style={{fontStyle: "italic", marginRight: 10}}>
                                                            {t('Uptime')}: </span>
                                                        {row.uptime_seconds ? formatSecondsInterval(row.uptime_seconds, true) : "N/A"}
                                                    </Typography>
                                                    <Typography variant="subtitle2">
                                                        <span style={{fontStyle: "italic", marginRight: 10}}>
                                                            {t('Reboot counter')}: </span>
                                                        {row.reboot_count || "N/A"}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        )) : (
                            <TableRow>
                                <TableCell style={{paddingBottom: 30, paddingTop: 30}}
                                           colSpan={isOTAUpdateMode ? 10 : 9} align="center">
                                    No results found.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {currentSelectedSmartReader &&
                <PopUpMeterReaderInfo
                    open={displaySmartReaderPopUp}
                    openSetter={setDisplaySmartReaderPopUp}
                    smartReader={currentSelectedSmartReader}
                />
            }
            <SmartReaderEditForm
                isOpen={Boolean(isPopUpReaderEditOpen)}
                setIsOpen={setIsPopUpReaderEditOpen}
                smartReader={isPopUpReaderEditOpen}
            />
            <SmartReaderActivationForm
                isOpen={Boolean(isPopUpReaderActivationOpen)}
                setIsOpen={setIsPopUpReaderActivationOpen}
                smartReader={isPopUpReaderActivationOpen}
            />
            <SmartReaderUploadPhotoForm
                isOpen={Boolean(isPopUpUploadPhotoOpen)}
                setIsOpen={setIsPopUpUploadPhotoOpen}
                smartReader={isPopUpUploadPhotoOpen}
            />
        </>
    )
}

export default SmartReadersConsole
