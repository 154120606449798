import React, {useEffect, useState} from 'react';
import {useQuery} from '@tanstack/react-query';

import {API_URLS, DEFAULT_GW_QUERY_OPTIONS, QUERY_KEYS} from '../../consts/consts';
import {callApiV2} from '../../services/server_apiV2';
import GatewayManagementDropDowns from './GatewayManagement/GatewayManagementDropDowns';
import GatewaysMap from './GatewaysMap/GatewaysMap'
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import {useAuth} from "../../hooks/AuthProvider";
import {USER_HOME_ROUTES} from "../../consts/routes";
import {useNavigate} from "react-router-dom";


const GatewayConsole = () => {
    const {currentUser} = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const {isFetching: isOrgFetching} = useQuery({
        queryKey: [QUERY_KEYS.ORGANIZATIONS],
        queryFn: async () =>
            await callApiV2({url: API_URLS.ORGANIZATIONS}).then((res) =>
                res.success ? res.data : Promise.reject(new Error(res.data))
            )
    });
    const {isFetching: isGwFetching} = useQuery({
        queryKey: [QUERY_KEYS.GATEWAYS_LINKED],
        queryFn: async () =>
            await callApiV2({url: API_URLS.GATEWAYS, params: DEFAULT_GW_QUERY_OPTIONS}).then((res) =>
                res.success ? res.data : Promise.reject(new Error(res.data))
            ),
        refetchInterval: 1000 * 60 * 5,
    });
    const {isFetching: isProfileFetching} = useQuery({
        queryKey: [QUERY_KEYS.PROFILES],
        queryFn: async () =>
            await callApiV2({url: API_URLS.PROFILES}).then((res) =>
                res.success ? res.data : Promise.reject(new Error(res.data))
            )
    });

    useEffect(() => {
        if (!loading) return;
        setLoading(isOrgFetching || isGwFetching || isProfileFetching)
    }, [isOrgFetching, isGwFetching, isProfileFetching]);

    if (loading)
        return <LoadingScreen/>;

    if (['wm', 'manufacturer'].includes(currentUser.role)) {
        USER_HOME_ROUTES[currentUser.role] && navigate(USER_HOME_ROUTES[currentUser.role]);
        return (
            <div>
                <div>You don't have access to this page.</div>
            </div>
        )
    }

    return (
        <div style={{display: "flex", gap: '2rem'}}>
            <GatewayManagementDropDowns/>
            <GatewaysMap/>
        </div>
    );
};

export default GatewayConsole;
