import React from 'react';
import {useField} from 'formik';
import TextField from "@mui/material/TextField";


export const FormikTextField = ({label, ...props}) => {
    const [field, meta] = useField(props);
    return (
        <>
            <TextField
                {...field}
                {...props}
                label={label}
                margin="dense"
                fullWidth
                variant="outlined"
                error={Boolean(meta.touched && meta.error)}
                helperText={(meta.touched && meta.error) || " "}
            />
        </>
    );
};
