import React, {useMemo, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {useQuery, useQueryClient} from '@tanstack/react-query';
import _ from "lodash";

import {API_URLS, QUERY_KEYS} from '../../../consts/consts';
import SmartReaderProvisionForm from './SmartReaderProvisionForm';
import {callApiV2} from '../../../services/server_apiV2';
import {notificationSuccess} from "../../../utils/utils";

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import AddLinkIcon from "@mui/icons-material/AddLink";
import SmartReaderToGatewayLink from "../../GatewaysConsolePage/Forms/SmartReaderToGatewayLink";
import SmartReaderEditForm from "../../SmartReadersConsolePage/Forms/SmartReaderEditForm";
import EditIcon from "@mui/icons-material/Edit";
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";
import {useAuth} from "../../../hooks/AuthProvider";
import SearchBar from "@mkyy/mui-search-bar";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";


// Should be moved to separate component
const SortableTableCell = ({label, field, order, orderBy, onSort}) => {
    return (
        <TableCell>
            <TableSortLabel
                active={field === orderBy}
                direction={orderBy === field ? order : 'asc'}
                onClick={() => onSort && onSort(field)}
            >
                {label}
            </TableSortLabel>
        </TableCell>
    )
}

function descendingComparator(a, b, orderBy) {
    if (_.get(b, orderBy, 0) < _.get(a, orderBy, 0)) {
        return -1;
    }
    if (_.get(b, orderBy, 0) > _.get(a, orderBy, 0)) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


const UnlinkedDevicesTable = () => {
    const {t, i18n} = useTranslation();
    const {currentUser} = useAuth();
    const queryClient = useQueryClient();
    const [searchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState(searchParams ? searchParams.get('search') : '');

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState(null);

    const [displayDeviceProvisionPopUp, setDisplayDeviceProvisionPopUp] = useState(false);
    const [displayDeviceEditPopUp, setDisplayDeviceEditPopUp] = useState(false);
    const [displayLinkPopUp, setDisplayLinkPopUp] = useState(false);
    const [selectedSmartReader, setSelectedSmartReader] = useState(false);

    const {data: smartReadersObjects, isLoading: isDevicesLoading} = useQuery({
        queryKey: [QUERY_KEYS.SMART_READERS_UNLINKED],
        queryFn: () => callApiV2({
            url: API_URLS.SMART_READERS,
            params: {is_linked_to_gw: 'False'}
        }).then((res) =>
            res.success ? res.data : Promise.reject(new Error(res.data))
        )
    });

    const handleRemoveSR = (eui) => {
        if (confirm(t(`Are you sure? (eui: {{eui}})`, {eui: eui}))) {
            callApiV2({url: `${API_URLS.SMART_READERS}${eui}/`, method: "DELETE"}).then(res => {
                if (res.success) {
                    notificationSuccess(t(`Smart Reader (eui: {{eui}}) successfully removed`, {eui: eui}))
                    queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SMART_READERS_UNLINKED]});
                }
            })
        }
    }

    // Table helpers
    function flattenObject(obj, prefix = '') {
        let result = {};
        for (const key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                Object.assign(result, flattenObject(obj[key], `${prefix}${key}.`));
            } else {
                result[`${prefix}${key}`] = obj[key];
            }
        }
        return result;
    }

    const onSort = (field) => {
        const isAsc = orderBy === field && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(field);
    }

    const filteredSRObjects = useMemo(
        () => smartReadersObjects
            ?.filter(item => {
                if (!searchQuery) return true;

                const flatItem = flattenObject(item);
                return Object.values(flatItem).some(value =>
                    value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
                );
            })
            .sort(getComparator(order, orderBy)),
        [smartReadersObjects, searchQuery, order, orderBy]
    )

    return (
        <>
            <Box style={{display: "flex", justifyContent: "space-between", margin: "10px 0px"}}>
                <SearchBar
                    style={{border: '1px solid #1976d2', alignSelf: 'end'}}
                    value={searchQuery}
                    onChange={newValue => setSearchQuery(newValue)}
                    onSearch={() => {
                    }}
                    onCancelResearch={() => setSearchQuery('')}
                />
                {['superuser', 'wm', "manufacturer"].includes(currentUser.role) && (
                    <Button
                        id='register-sr-button'
                        color="secondary"
                        variant="contained"
                        onClick={() => setDisplayDeviceProvisionPopUp(true)}
                        style={{display: 'block', textTransform: "capitalize"}}
                    >
                        {t("Register Smart Reader")}
                    </Button>
                )}
            </Box>

            <TableContainer sx={{mt: 2}} component={Paper}>
                <Table aria-label="collapsible table" size={"small"}>
                    <TableHead>
                        <TableRow>
                            <SortableTableCell label="Smart Reader EUI" field={"eui"} onSort={onSort} order={order}
                                               orderBy={orderBy}/>
                            <SortableTableCell label="Join EUI" field={"join_eui"} onSort={onSort} order={order}
                                               orderBy={orderBy}/>
                            <SortableTableCell label="App Key" field={"app_key"} onSort={onSort} order={order}
                                               orderBy={orderBy}/>
                            <SortableTableCell label="Network Key" field={"nwk_key"} onSort={onSort} order={order}
                                               orderBy={orderBy}/>
                            <SortableTableCell label="Organization" field={"organization.name"} onSort={onSort}
                                               order={order}
                                               orderBy={orderBy}/>
                            {!['helpdesk'].includes(currentUser.role) && <TableCell>Actions</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredSRObjects?.length ? filteredSRObjects.map((row) => (
                            <React.Fragment key={row.eui}>
                                <TableRow>
                                    <TableCell>{row.eui}</TableCell>
                                    <TableCell>{row.join_eui}</TableCell>
                                    <TableCell>{row.app_key}</TableCell>
                                    <TableCell>{row.nwk_key}</TableCell>
                                    <TableCell>{row.organization?.name}</TableCell>
                                    {!['helpdesk'].includes(currentUser.role) && (
                                        <TableCell>
                                            <>
                                                {!["manufacturer"].includes(currentUser.role) && (
                                                    <Tooltip title={t("Link to Meter")}>
                                                        <IconButton
                                                            id='link-sr-button'
                                                            disabled={!row.organization}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setDisplayLinkPopUp(true);
                                                                setSelectedSmartReader(row)
                                                            }}
                                                        >
                                                            <AddLinkIcon
                                                                style={{color: row.organization ? "green" : "grey"}}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                <Tooltip title={t("Edit Smart Reader")}>
                                                    <IconButton
                                                        id='edit-sr-button'
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setDisplayDeviceEditPopUp(true)
                                                            setSelectedSmartReader(row)
                                                        }}
                                                    >
                                                        <EditIcon style={{color: "orange"}}/>
                                                    </IconButton>
                                                </Tooltip>
                                                {!['utility_tech'].includes(currentUser.role) && (
                                                    <Tooltip title={t("Remove Smart Reader")}>
                                                        <IconButton
                                                            id='remove-sr-button'
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleRemoveSR(row.eui)
                                                            }}
                                                        >
                                                            <DeleteIcon style={{color: "red"}}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </>
                                        </TableCell>
                                    )}
                                </TableRow>
                            </React.Fragment>
                        )) : (
                            <TableRow>
                                <TableCell style={{paddingBottom: 30, paddingTop: 30}}
                                           colSpan={3} align="center">
                                    No results found.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <SmartReaderProvisionForm
                open={displayDeviceProvisionPopUp}
                openSetter={setDisplayDeviceProvisionPopUp}
            />

            {selectedSmartReader &&
                <SmartReaderEditForm
                    isOpen={displayDeviceEditPopUp}
                    setIsOpen={setDisplayDeviceEditPopUp}
                    smartReader={selectedSmartReader}
                />
            }
            {selectedSmartReader &&
                <SmartReaderToGatewayLink
                    open={displayLinkPopUp}
                    openSetter={setDisplayLinkPopUp}
                    smartReader={selectedSmartReader}
                />
            }
        </>
    )
}

export default UnlinkedDevicesTable
