import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import UnlinkedGWTable from './GatewaysPool/UnlinkedGWTable';
import UnlinkedDevicesTable from './SmartReadersPool/UnlinkedDevicesTable';
import {useQuery} from '@tanstack/react-query';
import {API_URLS, QUERY_KEYS} from 'consts/consts';
import {callApiV2} from 'services/server_apiV2';
import {useTranslation} from "react-i18next";


function tabProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const DevicePool = () => {
    const {t} = useTranslation()
    const [tabIndex, setTabIndex] = React.useState(0);
    const handleChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    // load organizations
    useQuery({
        queryKey: [QUERY_KEYS.ORGANIZATIONS],
        queryFn: async () =>
            await callApiV2({url: API_URLS.ORGANIZATIONS}).then((res) =>
                res.success ? res.data : Promise.reject(new Error(res.data))
            )
    });

    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs
                    variant="fullWidth"
                    value={tabIndex} onChange={handleChange}
                    sx={{
                        "& .MuiTab-root.Mui-selected": {color: "#c05151"},
                        "& .MuiTabs-indicator": {bgcolor: "#c05151"}
                    }}
                >
                    <Tab label={t("Gateways")} {...tabProps(0)} />
                    <Tab label={t("Smart Readers")} {...tabProps(1)} />
                </Tabs>
            </Box>

            {tabIndex === 0 ?
                <UnlinkedGWTable/>
                :
                <UnlinkedDevicesTable/>
            }
        </Box>
    )
}

export default DevicePool
