import {Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from "react-i18next";

import styles from './LayoutStyles.module.css';

export const Footer = () => {
    const {t} = useTranslation()
    return (
        <div className={styles.footerContainer}>
            <Typography
                sx={{
                    color: '#909090'
                }}
            >
                {t('Copyright © 2024 | All Rights Reserved. MarchlMetering is a registered trademark of Marchl Metering Software.')}
            </Typography>
        </div>
    );
};
