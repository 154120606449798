import React, {useState} from 'react';
import {notificationError, notificationSuccess} from '../../../utils/utils';
import {useTranslation} from "react-i18next";
import {callApiV2} from "../../../services/server_apiV2";
import {API_URLS, QUERY_KEYS} from "../../../consts/consts";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Collapse from "@mui/material/Collapse";

import {Form, Formik} from 'formik';
import {FormikTextField} from '../../../components/formikFields/FormikTextField'
import {FormikAutoComplete} from '../../../components/formikFields/FormikAutoComplete'
import * as Yup from 'yup';
import Typography from "@mui/material/Typography";
import {FormikSwitch} from "../../../components/formikFields/FormikSwitch";

import {INTERVAL_OPTIONS} from "../../GatewaysConsolePage/Forms/SmartReaderToGatewayLink";


const SmartReaderEditForm = ({isOpen, setIsOpen, smartReader}) => {
    const {t} = useTranslation();
    const queryClient = useQueryClient();
    const [retry, setRetry] = useState(false)  //Just for notification. Should be removed after implementing good GW API

    const orgQuery = useQuery({
        queryKey: [QUERY_KEYS.ORGANIZATIONS],
        queryFn: async () =>
            await callApiV2({url: API_URLS.ORGANIZATIONS}).then((res) =>
                res.success ? res.data : Promise.reject(new Error(res.data))
            )
    });
    const organizationsObjects = orgQuery.data || [];

    const handleUnlinkMeter = (smartReader) => {
        if (confirm(t(`Are you sure? (eui: {{eui}})`, {eui: smartReader.eui}))) {
            const data = {
                gateway: smartReader.gateway,
                is_linked: false,
            };
            callApiV2({
                url: API_URLS.READERS_LINK(smartReader.eui),
                method: 'POST',
                data
            }).then(
                (res) => {
                    if (!res.success) {
                        notificationError(t(res.short_message, {status: res.status}), res.response);
                        return;
                    }
                    notificationSuccess(t("Smart Reader successfully unlinked"));
                    queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SMART_READERS_LINKED]});
                    queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SMART_READERS_UNLINKED]});
                    queryClient.invalidateQueries({queryKey: [QUERY_KEYS.GATEWAYS_LINKED]});
                    setIsOpen(false)
                }
            );
        }
    };

    const handleRebootSR = smartReader => {
        callApiV2({
            url: `${API_URLS.SMART_READERS}${smartReader.eui}/reboot/`,
            method: 'POST',
        }).then(
            (res) => {
                if (!res.success) {
                    notificationError(t(res.short_message, {status: res.status}), res.response);
                    return;
                }
                notificationSuccess(t("Smart Reader will be rebooted soon"));
            }
        );
    }

    const handleEditDevice = (data) => {
        // Hack for removing nwk_key from form and make it the same as app_key
        const patchedData = {
            ...data,
            nwk_key: data.app_key,
            organization: data.organization?.slug,
            lmn_uplink_interval: data.lmn_uplink_interval.value,
            cloud_uplink_interval: data.cloud_uplink_interval.value,
            meter_type: data.meter_type?.value,
        }
        callApiV2({
            url: API_URLS.SMART_READERS + data.eui + '/',
            method: 'PATCH',
            data: patchedData
        }).then((res) => {
            if (res.success) {
                if (retry) {
                    notificationSuccess(t('Synchronization process started for Smart Reader (eui: {{eui}}). Wait for result.', {eui: data.eui}));
                } else if (!smartReader.activated) {
                    notificationSuccess(t('Smart Reader (eui: {{eui}}) successfully saved.', {eui: data.eui}));
                } else {
                    notificationSuccess(t('Smart Reader (eui: {{eui}}) successfully saved. Wait for synchronization result', {eui: data.eui}));
                }

                setRetry(false);
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SMART_READERS_LINKED]});
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SMART_READERS_UNLINKED]});
                setIsOpen(false)
            }
            return res;
        });
    };

    const validationSchema = Yup.object({
        eui: Yup.string().required('Required').matches(/^[0-9A-Fa-f]{16}$/, "Should have numbers [0-9] and letters [a-f]. 16 chars only"),
        join_eui: Yup.string().required('Required').matches(/^[0-9A-Fa-f]{16}$/, "Should have numbers [0-9] and letters [a-f]. 16 chars only"),
        name: smartReader.gateway
            ? Yup.string().required('Required').min(3, 'The name is too short').max(50, 'The name is too long')
            : undefined,
        address: smartReader.gateway
            ? Yup.string().required('Required')
            : undefined,
        organization: smartReader.gateway
            ? Yup.object().required('Required')
            : undefined,
        meter_id: smartReader.gateway
            ? Yup.string().required('Required').max(32, "Number of symbols should be lower than 32")
            : undefined,
        meter_type: smartReader.gateway
            ? Yup.object().required('Required')
            : undefined,
        app_key: Yup.string().required('Required').matches(/^[0-9A-Fa-f]{32}$/, '128-bit string required. [0-9] numbers and [a-f] letters are available'),
        lmn_uplink_interval: Yup.object().required('Required'),
        cloud_uplink_interval: Yup.object().required('Required'),
        lmn_mode_enabled: Yup.bool().required('Required'),
        non_lmn_mode_enabled: Yup.bool().required('Required'),
    })

    const typeOptions = [
        {value: 'Electricity', label: t('Electricity')},
        {value: 'Water', label: t('Water')},
        {value: 'Gas', label: t('Gas')}
    ];

    return (
        <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            fullWidth={true}
            maxWidth={'md'}
        >
            <DialogTitle>{t('Smart Reader Edit')}</DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{
                        ...smartReader,
                        meter_id: smartReader?.meter?.id,
                        meter_type: typeOptions.find(mType => mType.value === smartReader?.meter?.meter_type) ?? null,
                        lmn_uplink_interval: INTERVAL_OPTIONS.find(int => int.value === smartReader.lmn_uplink_interval) ?? INTERVAL_OPTIONS[1],
                        cloud_uplink_interval: INTERVAL_OPTIONS.find(int => int.value === smartReader.cloud_uplink_interval) ?? INTERVAL_OPTIONS[4]
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleEditDevice}
                >
                    {({values}) => (
                        <Form>
                            <Typography sx={{
                                fontWeight: 500,
                                fontSize: '1.1rem'
                            }}>{t('Smart Reader General Settings')}</Typography>
                            <FormikTextField disabled type="text" label={t('EUI')} name="eui" size="small"
                                             placeholder={t('EUI, for example: 4a9b2c7d6e5fa81d')}/>
                            {smartReader.gateway && (
                                <FormikTextField type="text" label={t('Name')} name="name" size="small"
                                                 placeholder={t('Smart Reader Name, for example: Smart Reader 1')}/>
                            )}
                            {smartReader.gateway && (
                                <FormikTextField type="text" label={t('Address')} name="address" size="small"
                                                 placeholder={t('Reader physical address, for example: Germany, Junge Strasse 1B')}/>
                            )}
                            {smartReader.gateway && !smartReader.activated && (
                                <FormikTextField type="text" label={t('Meter ID')} name="meter_id" size="small"
                                                 placeholder={t('Unique Meter ID, for example: 0102030405060708')}/>
                            )}
                            {smartReader.gateway && !smartReader.activated && (
                                <FormikAutoComplete options={typeOptions} label={t("Meter Type")} name="meter_type"
                                                    size="small"/>
                            )}
                            <FormikTextField type="text" label={t('Join EUI')} name="join_eui" size="small"
                                             placeholder={t('Join EUI, for example: 4a9b2c7d6e5fa81d')}/>
                            {(!smartReader.organization || !smartReader.gateway) && (
                                <FormikAutoComplete
                                    options={organizationsObjects.map((org) => ({...org, label: org.name}))}
                                    label={t("Organization name")} name="organization" size="small"/>
                            )}
                            <FormikSwitch label={t("Enable Metering Data via SMGw?")} name="lmn_mode_enabled"/>
                            <Collapse in={values.lmn_mode_enabled}>
                                <FormikAutoComplete options={INTERVAL_OPTIONS}
                                                    label={t('Metering Data via SMGw Interval')}
                                                    name="lmn_uplink_interval" size="small"
                                                    placeholder={t('Metering Data via SMGw Interval')}/>
                            </Collapse>
                            <FormikSwitch label={t("Enable Metering Data via Cloud?")} name="non_lmn_mode_enabled"/>
                            <Collapse in={values.non_lmn_mode_enabled}>
                                <FormikAutoComplete options={INTERVAL_OPTIONS}
                                                    label={t('Metering Data via Cloud Interval')}
                                                    name="cloud_uplink_interval"
                                                    size="small"
                                                    placeholder={t('Metering Data via Cloud Interval')}/>
                            </Collapse>

                            <Typography sx={{fontWeight: 500, fontSize: '1.1rem'}}>{t('Smart Reader Keys')}</Typography>
                            <FormikTextField type="text" label={t('App Key')} name="app_key" size="small"
                                             placeholder={t('App Key, for example: c5c16ec5122cb97b9da30e2d86892803')}/>

                            <DialogActions sx={{p: 0}} style={{justifyContent: "space-between"}}>
                                <div style={{display: "flex", gap: 10}}>
                                    {smartReader.gateway && (
                                        <Button
                                            id="edit-sr-form-unlink-button"
                                            onClick={() => handleUnlinkMeter(smartReader)}
                                            disableElevation
                                            variant="contained"
                                            color="error"
                                        >
                                            {t('Unlink')}
                                        </Button>
                                    )}

                                    {smartReader.provisioning_synced && smartReader.provisioning_error_code !== 0 && (
                                        <Button
                                            id="edit-sr-form-retry-button"
                                            type="submit"
                                            disableElevation
                                            variant="contained"
                                            color="additional"
                                            onClick={() => setRetry(true)}
                                        >
                                            {t('Retry')}
                                        </Button>
                                    )}

                                    {/*TODO: uncomment when ready on GW*/}
                                    {/*{smartReader.gateway && (*/}
                                    {/*    <Button*/}
                                    {/*        id="edit-sr-form-reboot-button"*/}
                                    {/*        onClick={() => handleRebootSR(smartReader)}*/}
                                    {/*        disableElevation*/}
                                    {/*        variant="contained"*/}
                                    {/*        color="additional"*/}
                                    {/*    >*/}
                                    {/*        {t('Reboot')}*/}
                                    {/*    </Button>*/}
                                    {/*)}*/}
                                </div>
                                <div style={{display: "flex", gap: 10}}>
                                    <Button
                                        id="edit-sr-form-cancel-button"
                                        onClick={() => setIsOpen(false)}
                                        disableElevation
                                        variant="contained"
                                        color="warning"
                                    >
                                        {t('Close')}
                                    </Button>
                                    <Button
                                        id="edit-sr-form-submit-button"
                                        type="submit"
                                        disableElevation
                                        variant="contained"
                                        color="secondary"
                                    >
                                        {t('Save')}
                                    </Button>
                                </div>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};


export default SmartReaderEditForm;
