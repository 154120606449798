import React from 'react'
import {capitalize} from 'utils/utils';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';

const METER_TYPES_MAP = {
    'water': ['Water', '💧'],
    'gas': ['Gas', '🔥'],
    'electricity': ['Electricity', '⚡️'],
}


const SmartReaderTypeIcon = ({type}) => {
    const [label, icon] = METER_TYPES_MAP[type.toLowerCase().trim()]
    return (
        <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{timeout: 400}}
            title={capitalize(label)}
            placement="top"
        >
            <span>{icon}</span>
        </Tooltip>
    )
}

export default SmartReaderTypeIcon
