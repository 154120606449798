import React, {useState} from 'react';

import SearchBar from '@mkyy/mui-search-bar';

import styles from './GatewayManagementDropDowns.module.css';
import {getCountryFlag} from '../../../utils/utils';
import {useQueryClient} from '@tanstack/react-query';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import WarningIcon from '@mui/icons-material/Warning';

import {QUERY_KEYS} from '../../../consts/consts';
import GatewayManagementDetails from "./GatewayManagementDetails";
import SmartReaderToGatewayLink from '../Forms/SmartReaderToGatewayLink'
import ConnectionStatus from '../../../components/ConnectionStatus/ConnectionStatus'

// Table
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";
import {IconButton} from "@mui/material";
import {useAuth} from "../../../hooks/AuthProvider";


const GatewayManagementDropDowns = () => {
    const {t} = useTranslation();
    const {currentUser} = useAuth();
    const [searchParams] = useSearchParams();
    const [displayGatewaySmartMeterLinkPopUp, setDisplayGatewaySmartMeterLinkPopUp] = useState(false);
    const [currentSelectedGateway, setCurrentSelectedGateway] = useState();
    const [searchQuery, setSearchQuery] = useState(searchParams ? searchParams.get('search') : '');

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const gatewaysObjects = useQueryClient().getQueryData([QUERY_KEYS.GATEWAYS_LINKED]);

    if (!gatewaysObjects || gatewaysObjects.length === 0) return <p>{t('No gateways found')}</p>; // return <LoadingScreen />

    return (
        <div style={{width: '100%', display: "flex", flexDirection: "column"}}>
            <SearchBar
                style={{border: '1px solid #1976d2', alignSelf: 'end'}}
                value={searchQuery}
                onChange={newValue => setSearchQuery(newValue)}
                onSearch={() => {
                }}
                onCancelResearch={() => setSearchQuery('')}
            />
            {gatewaysObjects.filter(gateway => {
                if (!searchQuery) return true;
                return gateway.name.includes(searchQuery) || gateway.organization.name.includes(searchQuery)
            }).map((gateway, index) => (
                <div key={index}>
                    <Accordion
                        style={{margin: '0.5rem 0'}}
                        expanded={expanded === gateway.id}
                        onChange={handleChange(gateway.id)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div style={{alignItems: 'center', display: 'flex', flex: 1, gap: 10}}>
                                {getCountryFlag(gateway.address)}
                                <ConnectionStatus status={gateway.status.status}/>
                                <span>{gateway.name}</span>
                                <span className={styles.gwDropdownName}>({gateway.id})</span>

                                {["success", "fail"].includes(gateway.firmware.status) &&
                                    <WarningIcon
                                        style={{color: gateway.firmware.status === "success" ? "green" : "red"}}/>
                                }

                                {!['helpdesk'].includes(currentUser.role) && (
                                    <IconButton
                                        id="link-meter-to-gw-button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setDisplayGatewaySmartMeterLinkPopUp(true);
                                            setCurrentSelectedGateway(gateway);
                                        }}
                                        style={{marginLeft: 'auto'}}
                                    >
                                        <AddToQueueIcon style={{color: "green"}}/>
                                    </IconButton>
                                )}
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <GatewayManagementDetails
                                gateway={gateway}
                                disableActions={['helpdesk'].includes(currentUser.role)}
                            />
                        </AccordionDetails>
                    </Accordion>
                </div>
            ))}

            {currentSelectedGateway &&
                <>
                    <SmartReaderToGatewayLink
                        open={displayGatewaySmartMeterLinkPopUp}
                        openSetter={setDisplayGatewaySmartMeterLinkPopUp}
                        gateway={currentSelectedGateway}
                    />
                </>
            }
        </div>
    );
};

export default GatewayManagementDropDowns;
