import axios from 'axios';
import {BASE_URL} from "../consts/consts";
import {ROUTES} from "../consts/routes";
import i18n from "../i18n";

const axiosConfig = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosConfig.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem('accessToken'); // get stored access token
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`; // set in header
        }

        config.headers['Accept-Language'] = i18n.resolvedLanguage;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosConfig.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401) {

            const refreshToken = localStorage.getItem('refreshToken');
            if (refreshToken) {
                try {
                    const response = await axios.post(`${BASE_URL}api/token/refresh/`, {refresh: refreshToken});
                    // don't use axios instance that already configured for refresh token api call
                    const newAccessToken = response.data?.access;
                    localStorage.setItem('accessToken', newAccessToken);
                    localStorage.setItem('refreshToken', refreshToken);
                    originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                    const newAxiosInstance = axios.create({
                        baseURL: BASE_URL,
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${newAccessToken}`, // Set new access token
                        },
                    });
                    return newAxiosInstance(originalRequest); //recall Api with new token
                } catch (error) {
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('refreshToken');
                    window.location = ROUTES.LOGIN;
                }
            }
        }
        return Promise.reject(error);
    }
);

export default axiosConfig;