export const ROUTES = {
    LOGIN: '/login',
    SIGNUP: '/signup',
    CONFIRM_INVITE: '/confirm_invite',
    FORGOT_PASSWORD: '/forgot_password',
    FORGOT_PASSWORD_CONFIRM: '/reset_password',
    ORGANIZATIONS: '/organizations',
    GATEWAYS: '/gcc/gateways',
    END_SMART_READERS: '/gcc/end_devices',
    SMART_READER_POOL: '/gcc/device_pool',
};

export const USER_HOME_ROUTES = {
    superadmin: ROUTES.ORGANIZATIONS,
    helpdesk: ROUTES.ORGANIZATIONS,
    wm: ROUTES.SMART_READER_POOL,
    manufacturer: ROUTES.SMART_READER_POOL,
    utility_admin: ROUTES.ORGANIZATIONS,
    utility_tech: ROUTES.GATEWAYS,
}