import {useQuery, useQueryClient} from "@tanstack/react-query";
import {notificationError, notificationSuccess} from "../../../utils/utils";
import {API_URLS, DEFAULT_GW_QUERY_OPTIONS, QUERY_KEYS} from "../../../consts/consts";
import React from "react";
import {useTranslation} from "react-i18next";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import {Form, Formik} from 'formik';
import {FormikTextField} from '../../../components/formikFields/FormikTextField'
import {FormikAutoComplete} from '../../../components/formikFields/FormikAutoComplete'
import * as Yup from 'yup';
import {callApiV2} from "../../../services/server_apiV2";


const GatewayUpdateFirmwareForm = ({isOpen, setIsOpen, gateway}) => {
    const {t} = useTranslation()
    const queryClient = useQueryClient();

    if (!gateway) return;

    const firmwareQuery = useQuery({
        queryKey: [QUERY_KEYS.GATEWAY_FIRMWARE_VERSIONS],
        queryFn: async () =>
            await callApiV2({url: API_URLS.GATEWAY_FIRMWARE_VERSIONS}).then((res) =>
                res.success ? res.data : Promise.reject(new Error(res.data))
            )
    });
    const firmwareVersionObjects = firmwareQuery.data || [];


    const handleFormSubmit = (values) => {
        const dataRequest = {
            // gatewayId: gateway.id,
            version: values.version.version,

        };
        callApiV2({url: API_URLS.GATEWAYS + gateway.id + '/firmware_update/', method: 'POST', data: dataRequest}).then(
            (res) => {
                if (!res.success) {
                    notificationError(t(res.short_message, {status: res.status}), res.response);
                    return;
                }

                notificationSuccess(t("Firmware update request successfully saved. Note: firmware will update later"));
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.GATEWAYS_LINKED]})
                setIsOpen(false);
                return res;
            }
        );
    };

    const validationSchema = Yup.object({
        version: Yup.object().required('Required'),
    })


    return (
        <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            fullWidth={true}
            maxWidth={'md'}
        >
            <DialogTitle>{t('Gateway Update Firmware')}</DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{version: ""}}
                    validationSchema={validationSchema}
                    onSubmit={handleFormSubmit}
                >
                    <Form>
                        <FormikAutoComplete options={firmwareVersionObjects} lookupField="version"
                                            label={t("Firmware version")} name="version" size="small"/>

                        <DialogActions sx={{p: 0}}>
                            <Button
                                id="edit-gw-close-button"
                                onClick={() => setIsOpen(false)}
                                disableElevation
                                variant="contained"
                                color="warning"
                            >
                                {t('Close')}
                            </Button>
                            <Button
                                id="edit-gw-submit-button"
                                type="submit"
                                disableElevation
                                variant="contained"
                                color="secondary"
                            >
                                {t('Update')}
                            </Button>
                        </DialogActions>
                    </Form>
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default GatewayUpdateFirmwareForm
