import React, {useState} from 'react';

import {useTranslation} from "react-i18next";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";

import {notificationError, notificationSuccess} from "../../../utils/utils";
import {API_URLS, QUERY_KEYS} from "../../../consts/consts";
import {callApiV2} from "../../../services/server_apiV2";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Tooltip from "@mui/material/Tooltip";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {IconButton} from "@mui/material";
import {RemoveRedEye} from "@mui/icons-material";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Link from "@mui/material/Link";
import {ROUTES} from "../../../consts/routes";

import CreateOrganizationModal from "./CreateOrganizationModal";
import UserInviteForm from "./UserInviteForm";

import styles from "../../GatewaysConsolePage/GatewayManagement/GatewayManagementDropDowns.module.css";
import {deDE} from "@mui/x-data-grid/locales";


const OrganizationManagement = ({orgs, showOrgUsers, disableActions}) => {
    const {t, i18n} = useTranslation()
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [inviteOrg, setInviteOrg] = useState(null);

    const withoutOrgsUsersQuery = useQuery({
        queryKey: [QUERY_KEYS.USERS_WITHOUT_ORG],
        queryFn: async () =>
            await callApiV2({url: API_URLS.USERS + '?without_org=true'}).then((res) =>
                res.success ? res.data : Promise.reject(new Error(res.data))
            ),
        enabled: showOrgUsers,
    });

    const handleRemoveOrg = (org) => {
        if (confirm(t(`Are you sure? (name: {{name}})`, {name: org.name}))) {
            callApiV2({url: `${API_URLS.ORGANIZATIONS}${org.slug}/`, method: "DELETE"}).then(res => {
                if (res.success) {
                    notificationSuccess(t(`Organization ({{name}}) successfully removed`, {name: org.name}))
                    queryClient.invalidateQueries({queryKey: [QUERY_KEYS.ORGANIZATIONS]});
                } else {
                    if (res.status === 409) {
                        notificationError("Unlink Gateways and Smart Readers first")
                        return;
                    }
                    notificationError(t(res.short_message, {status: res.status}))
                }
            })
        }
    }

    return (
        <div style={{width: '100%'}}>
            {showOrgUsers && (
                <Accordion style={{margin: '0.5rem 0'}} slotProps={{transition: {unmountOnExit: true}}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div style={{alignItems: 'center', display: 'flex', flex: 1, justifyContent: "space-between"}}>
                            <div>
                                Marchl Metering Stuff
                                <span
                                    className={styles.gwDropdownName}>({withoutOrgsUsersQuery.data?.length} users)</span>
                            </div>

                            <div>
                                <Tooltip title={t("Invite users")}>
                                    <IconButton
                                        id="invite-users-button"
                                        onClick={e => {
                                            e.stopPropagation();
                                            setInviteOrg({name: "Marchl Metering Stuff"})
                                        }}
                                        style={{color: "green"}}
                                    >
                                        <PersonAddAltIcon/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                            {withoutOrgsUsersQuery.data?.length ? (
                                <Box sx={{width: '100%'}}>
                                    <DataGrid
                                        disableRowSelectionOnClick={true}
                                        disableColumnFilter={true}
                                        disableColumnSelector={true}
                                        disableDensitySelector={true}
                                        pageSizeOptions={[10, 25, 50]}

                                        initialState={{
                                            pagination: {
                                                paginationModel: {page: 0, pageSize: 25}
                                            },
                                            sorting: {
                                                sortModel: [{field: 'gw_name', sort: 'asc'}],
                                            },
                                        }}

                                        slotProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                printOptions: {disableToolbarButton: true},
                                                csvOptions: {disableToolbarButton: true}
                                            }
                                        }}
                                        slots={{toolbar: GridToolbar}}
                                        localeText={i18n.resolvedLanguage === 'de' ? deDE.components.MuiDataGrid.defaultProps.localeText : undefined}

                                        rows={withoutOrgsUsersQuery.data?.map((user, id) => (
                                            {
                                                id: id,
                                                firstName: user.first_name,
                                                lastName: user.last_name,
                                                email: user.email,
                                                role: user.role,
                                                action: (
                                                    <IconButton
                                                        // onClick={(e) => {
                                                        //     e.stopPropagation();
                                                        //     setDisplaySmartReaderPopUp(true);
                                                        //     setCurrentSelectedSmartReader(reader);
                                                        // }}
                                                    >
                                                        <RemoveRedEye/>
                                                    </IconButton>
                                                )
                                            })
                                        )}

                                        columns={
                                            [
                                                {
                                                    field: 'firstName',
                                                    headerName: t('First Name'),
                                                    flex: 1,
                                                    height: 'auto',
                                                },
                                                {
                                                    field: 'lastName',
                                                    headerName: t('Last Name'),
                                                    flex: 1,
                                                    height: 'auto',
                                                },
                                                {
                                                    field: 'email',
                                                    headerName: t('Email'),
                                                    flex: 1,
                                                    height: 'auto',
                                                },
                                                {
                                                    field: 'role',
                                                    headerName: t('Role'),
                                                    flex: 1,
                                                },
                                                {
                                                    field: 'action',
                                                    headerName: t('Action'),
                                                    height: 'auto',
                                                    renderCell: (params) => {
                                                        return params.value;
                                                    },
                                                    sortable: false
                                                }
                                            ]
                                        }
                                    />
                                </Box>
                            ) : (
                                <div>No users</div>
                            )}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            )}

            {orgs.map((org, index) => (
                <div key={index}>
                    <Accordion style={{margin: '0.5rem 0'}} slotProps={{transition: {unmountOnExit: true}}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div style={{
                                alignItems: 'center',
                                display: 'flex',
                                flex: 1,
                                justifyContent: "space-between"
                            }}>
                                <div>
                                    {org.name}
                                    <span className={styles.gwDropdownName}>({org.users.length} users)</span>
                                </div>

                                <div>
                                    <Link
                                        id="show-gw-button"
                                        style={{color: '#898989', marginLeft: '0.5rem', fontSize: '0.85rem'}}
                                        underline='hover'
                                        onClick={() => navigate(ROUTES.GATEWAYS + '?search=' + org.name)}
                                    >
                                        Show GW's
                                    </Link>
                                    <Link
                                        id="show-sr-button"
                                        style={{color: '#898989', marginLeft: '0.5rem', fontSize: '0.85rem'}}
                                        underline='hover'
                                        onClick={() => navigate(ROUTES.END_SMART_READERS + '?search=' + org.name)}
                                    >
                                        Show SR's
                                    </Link>
                                    <Link
                                        id="show-pool-button"
                                        style={{
                                            color: '#898989',
                                            marginLeft: '0.5rem',
                                            fontSize: '0.85rem',
                                            marginRight: 30
                                        }}
                                        underline='hover'
                                        onClick={() => navigate(ROUTES.SMART_READER_POOL + '?search=' + org.name)}
                                    >
                                        Show Pool
                                    </Link>
                                    {!disableActions && (
                                        <>
                                            <Tooltip title={t("Invite users")}>
                                                <IconButton
                                                    id="invite-users-button"
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        setInviteOrg(org)
                                                    }}
                                                    style={{color: "green"}}
                                                >
                                                    <PersonAddAltIcon/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={t("Edit Organization")}>
                                                <IconButton
                                                    id="edit-org-button"
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        setSelectedOrg(org)
                                                    }}
                                                    style={{color: "orange"}}
                                                >
                                                    <EditIcon/>
                                                </IconButton>
                                            </Tooltip>
                                            {showOrgUsers && (
                                                <Tooltip title={t("Remove Organization")}>
                                                    <IconButton
                                                        id="remove-org-button"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleRemoveOrg(org)
                                                        }}
                                                    >
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                {org.users.length ? (
                                    <Box sx={{width: '100%'}}>
                                        <DataGrid
                                            disableRowSelectionOnClick={true}
                                            disableColumnFilter={true}
                                            disableColumnSelector={true}
                                            disableDensitySelector={true}
                                            pageSizeOptions={[10, 25, 50]}

                                            initialState={{
                                                pagination: {
                                                    paginationModel: {page: 0, pageSize: 25}
                                                },
                                                sorting: {
                                                    sortModel: [{field: 'gw_name', sort: 'asc'}],
                                                },
                                            }}

                                            slotProps={{
                                                toolbar: {
                                                    showQuickFilter: true,
                                                    printOptions: {disableToolbarButton: true},
                                                    csvOptions: {disableToolbarButton: true}
                                                }
                                            }}
                                            slots={{toolbar: GridToolbar}}
                                            localeText={i18n.resolvedLanguage === 'de' ? deDE.components.MuiDataGrid.defaultProps.localeText : undefined}

                                            rows={org.users.map((user, id) => (
                                                {
                                                    id: id,
                                                    firstName: user.first_name,
                                                    lastName: user.last_name,
                                                    email: user.email,
                                                    role: user.role,
                                                    action: (
                                                        <IconButton
                                                            // onClick={(e) => {
                                                            //     e.stopPropagation();
                                                            //     setDisplaySmartReaderPopUp(true);
                                                            //     setCurrentSelectedSmartReader(reader);
                                                            // }}
                                                        >
                                                            <RemoveRedEye/>
                                                        </IconButton>
                                                    )
                                                })
                                            )}

                                            columns={
                                                [
                                                    {
                                                        field: 'firstName',
                                                        headerName: t('First Name'),
                                                        flex: 1,
                                                        height: 'auto',
                                                    },
                                                    {
                                                        field: 'lastName',
                                                        headerName: t('Last Name'),
                                                        flex: 1,
                                                        height: 'auto',
                                                    },
                                                    {
                                                        field: 'email',
                                                        headerName: t('Email'),
                                                        flex: 1,
                                                        height: 'auto',
                                                    },
                                                    {
                                                        field: 'role',
                                                        headerName: t('Role'),
                                                        flex: 1,
                                                    },
                                                    {
                                                        field: 'action',
                                                        headerName: t('Action'),
                                                        height: 'auto',
                                                        renderCell: (params) => {
                                                            if (disableActions) return <></>;
                                                            return params.value;
                                                        },
                                                        sortable: false
                                                    }
                                                ]
                                            }
                                        />
                                    </Box>
                                ) : (
                                    <div>No users</div>
                                )}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </div>
            ))}

            {selectedOrg && (
                <CreateOrganizationModal
                    initialOrg={selectedOrg}
                    handleCloseCallBack={() => setSelectedOrg(null)}
                />
            )}
            {inviteOrg && (
                <UserInviteForm
                    organization={inviteOrg}
                    handleCloseCallBack={() => setInviteOrg(null)}
                />
            )}
        </div>
    );
};

export default OrganizationManagement;
