import React from 'react';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {useTranslation} from "react-i18next";

import {callApiV2} from '../../../services/server_apiV2';
import {API_URLS, QUERY_KEYS} from '../../../consts/consts';

import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import {notificationError, notificationSuccess} from '../../../utils/utils';

import {Form, Formik} from 'formik';
import {FormikTextField} from '../../../components/formikFields/FormikTextField'
import {FormikAutoComplete} from '../../../components/formikFields/FormikAutoComplete'
import * as Yup from 'yup';


const SmartReaderProvisionForm = ({open, openSetter}) => {
    const {t} = useTranslation()
    const queryClient = useQueryClient();

    const orgQuery = useQuery({
        queryKey: [QUERY_KEYS.ORGANIZATIONS],
        queryFn: async () =>
            await callApiV2({url: API_URLS.ORGANIZATIONS}).then((res) =>
                res.success ? res.data : Promise.reject(new Error(res.data))
            )
    });
    const organizationsObjects = orgQuery.data || [];

    const onFormSubmit = (data) => {
        const dataRequest = {
            ...data,
            eui: data.eui.toLowerCase(),
            join_eui: data.join_eui.toLowerCase(),
            nwk_key: data.app_key,
            organization: data.organization?.slug,
        };
        callApiV2({url: API_URLS.SMART_READERS, method: 'POST', data: dataRequest}).then((res) => {
            if (!res.success) {
                notificationError(t(res.short_message, {status: res.status}), res.response);
                return;
            }
            notificationSuccess("Smart Reader successfully onboarded");
            queryClient.invalidateQueries({
                queryKey: [QUERY_KEYS.SMART_READERS_UNLINKED]
            });
            openSetter(false);
        });
    };

    const validationSchema = Yup.object({
        eui: Yup.string().required('Required').matches(/^[0-9A-Fa-f]{16}$/, "Should have numbers [0-9] and letters [a-f]. 16 chars only"),
        join_eui: Yup.string().required('Required').matches(/^[0-9A-Fa-f]{16}$/, "Should have numbers [0-9] and letters [a-f]. 16 chars only"),
        app_key: Yup.string().required('Required').matches(/^[0-9A-Fa-f]{32}$/, '128-bit string required. [0-9] numbers and [a-f] letters are available'),
        organization: Yup.object(),
    })

    return (
        <Dialog
            open={open}
            onClose={() => openSetter(false)}
            fullWidth={true}
            maxWidth={'md'}
        >
            <DialogTitle>{t('LoRa® Smart Reader Registration')}</DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{eui: '', join_eui: '', app_key: '', organization: ''}}
                    validationSchema={validationSchema}
                    onSubmit={onFormSubmit}
                >
                    <Form>
                        <Typography
                            sx={{fontWeight: 500, fontSize: '1.1rem'}}>{t('Smart Reader General Settings')}</Typography>
                        <FormikTextField type="text" label={t('EUI')} name="eui" size="small"
                                         placeholder={t('EUI, for example: 4a9b2c7d6e5fa81d')}/>
                        <FormikTextField type="text" label={t('Join EUI')} name="join_eui" size="small"
                                         placeholder={t('Join EUI, for example: 4a9b2c7d6e5fa81d')}/>

                        <Typography
                            sx={{fontWeight: 500, fontSize: '1.1rem'}}>{t('Smart Reader Ownership')}</Typography>
                        <FormikAutoComplete options={organizationsObjects?.map((org) => ({...org, label: org.name}))}
                                            label={t("Organization name")} name="organization" size="small"/>

                        <Typography sx={{fontWeight: 500, fontSize: '1.1rem'}}>{t('Smart Reader Keys')}</Typography>
                        <FormikTextField type="text" label={t('App Key')} name="app_key" size="small"
                                         placeholder={t('App Key, for example: c5c16ec5122cb97b9da30e2d86892803')}/>

                        <DialogActions sx={{p: 0}}>
                            <Button
                                id='register-sr-form-cancel-button'
                                onClick={() => openSetter(false)}
                                disableElevation
                                variant="contained"
                                color="warning"
                            >
                                {t('Close')}
                            </Button>
                            <Button
                                id='register-sr-form-submit-button'
                                type="submit"
                                disableElevation
                                variant="contained"
                                color="secondary"
                            >
                                {t('Register Smart Reader')}
                            </Button>
                        </DialogActions>
                    </Form>
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default SmartReaderProvisionForm;
