import axios from './axiosConfigV2';


export const callApiV2 = (
    request,
    onSuccess,
    onFailure
) => {
    const requestParams = {
        url: request.url,
        data: request?.data || {},
        headers: request?.headers || {},
        method: request?.method || "GET",
        params: request?.params || {}
    };
    return axios(requestParams)
        .then((res) => {
            onSuccess?.(res);
            return {
                success: true,
                data: res.data
            };
        })
        .catch((error) => {
            onFailure?.(error);
            return {
                success: false,
                response: error.response,
                short_message: "Request failed with status code {{status}}.",
                // errors: error.response.data,
                status: error.response.status
            };
        });
};
