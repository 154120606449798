import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import {useQueryClient} from '@tanstack/react-query';
import {CONNECTION_STATUS_CHOICES, QUERY_KEYS} from '../../../consts/consts';

function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

function randomizeArrayValues(array, range) {
    return array.map((value, index) => {
        const min = range[index][0];
        const max = range[index][1];
        return getRandomArbitrary(min, max);
    });
}

const originalArray = [48.1, 11.64];
const valueRanges = [
    [48.05, 48.15], // Range for the first value
    [11.5, 11.7] // Range for the second value
];

const GatewaysMap = () => {
    const gatewaysObjects = useQueryClient().getQueryData([QUERY_KEYS.GATEWAYS_LINKED]);
    const randomizedLocation = randomizeArrayValues(originalArray, valueRanges);

    const markerIconEnabled = L.icon({
        iconUrl: '/icons/signal-enabled.svg',
        iconSize: [32, 32],
        iconAnchor: [16, 32]
    });
    const markerIconDisabled = L.icon({
        iconUrl: '/icons/signal-disabled.svg',
        iconSize: [32, 32],
        iconAnchor: [16, 32]
    });

    if (!gatewaysObjects.length) return;

    return (
        <div style={{width: '35%'}}>
            <MapContainer
                center={[48.08, 11.5]}
                zoom={8}
                style={{width: '100%', height: '56vh', marginTop: 8, marginRight: 40}}
            >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" maxZoom={19}/>
                {gatewaysObjects.map((gw) => (
                    <Marker
                        key={gw.id}
                        position={randomizedLocation}
                        icon={gw.status.status === CONNECTION_STATUS_CHOICES.ONLINE
                            ? markerIconEnabled
                            : markerIconDisabled
                        }
                    >
                        <Popup>
                            <b>{gw.name}</b>
                        </Popup>
                    </Marker>
                ))}
            </MapContainer>
        </div>
    );
};

export default GatewaysMap;
