import React, {useState} from 'react';
import {Box, Paper, Typography} from "@mui/material";
import GatewayEditForm from "../Forms/GatewayEditForm";
import GatewayUpdateFirmwareForm from "../Forms/GatewayUpdateFirmwareForm"
import {notificationError, notificationSuccess} from "../../../utils/utils";
import {callApiV2} from "../../../services/server_apiV2";
import {API_URLS, DEFAULT_SMART_READER_QUERY_OPTIONS, QUERY_KEYS} from "../../../consts/consts";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";

// Others
import {ROUTES} from '../../../consts/routes';
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DevicesIcon from '@mui/icons-material/Devices';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import CloseIcon from '@mui/icons-material/Close';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import Tooltip from "@mui/material/Tooltip";
import {useTranslation} from "react-i18next";


const StatusMessages = {
    "pending": "(Pending Update)",
    "new": "(New Request)",
}


const GatewayManagementDetails = ({gateway, disableActions}) => {
    const {t} = useTranslation()
    const [isEditFormOpen, setIsEditFormOpen] = useState(false);
    const [isUpdateFirmwareFormOpen, setUpdateFirmwareFormOpen] = useState(false);
    const [queryOptions, setQueryOptions] = React.useState({
        ...DEFAULT_SMART_READER_QUERY_OPTIONS, gateway: gateway.id
    });
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const {data: smartReadersObjects, isLoading: isSmartMetersLoading} = useQuery({
        queryKey: [QUERY_KEYS.SMART_READERS_LINKED, gateway.id],
        queryFn: async () => await callApiV2({
            url: API_URLS.SMART_READERS,
            params: queryOptions
        }).then((res) => res.success ? res.data : Promise.reject(new Error(res.data)))
    }) || {data: [], isLoading: true};

    const unlinkGw = () => {
        // check, if there're any linked devices. If so, return error.
        if (isSmartMetersLoading) {
            notificationError(t('Loading... Wait...'))
            return
        } else if (smartReadersObjects.length) {
            notificationError(t('Unlink Smart Readers first'))
            return
        }

        callApiV2({
            url: API_URLS.GATEWAYS_LINKING(gateway.id), method: 'post', data: {
                is_linked: false, name: "", address: ""
            }
        }).then((res) => {
            if (!res.success) {
                notificationError(t(res.short_message, {status: res.status}), res.response);
                return;
            }

            notificationSuccess(t(`Gateway (name: {{name}}) unlinked`, {name: gateway.name}))
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.GATEWAYS_UNLINKED]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.GATEWAYS_LINKED]})
        })
    }

    const showDevices = () => {
        navigate(ROUTES.END_SMART_READERS + '?search=' + gateway.name);
    }

    const handleStatusFirmwareUpdateHide = () => {
        callApiV2({
            url: API_URLS.GATEWAYS + gateway.id + '/firmware_update_status/', method: 'post',
        }).then((res) => {

            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.GATEWAYS_LINKED]})
        })
    }

    return (<>
        {["success", "fail"].includes(gateway.firmware.status) && <Box display="flex" sx={{mb: 2}}>
            <Paper elevation={1}
                   sx={{
                       p: 0.5, width: 1, bgcolor: gateway.firmware.status === "success" ? "lightGreen" : "pink"
                   }}
                   style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}
            >
                <Typography fontWeight="bold" sx={{ml: 2}}>{gateway.firmware.description}</Typography>

                <Tooltip title={t("Close")}>
                    <IconButton
                        id="CloseIcon"
                        onClick={handleStatusFirmwareUpdateHide}
                    >
                        <CloseIcon/>
                    </IconButton>
                </Tooltip>
            </Paper>

        </Box>}
        <Box
            display="flex"
            gap={4}
        >
            <Paper elevation={1} sx={{p: 2, width: 1}}>
                <Typography fontWeight="bold" sx={{ml: 2}}>{t('Gateway Info')}:</Typography>
                <Typography variant="subtitle2">
                    <span style={{fontStyle: "italic", marginRight: 10}}>{t('Name')}: </span>{gateway.name}
                </Typography>
                <Typography variant="subtitle2">
                    <span style={{
                        fontStyle: "italic", marginRight: 10
                    }}>{t('Organization name')}: </span>{gateway.organization.name}
                </Typography>
                <Typography variant="subtitle2">
                    <span style={{fontStyle: "italic", marginRight: 10}}>{t('Address')}: </span>{gateway.address}
                </Typography>
                <Typography variant="subtitle2">
                        <span
                            style={{fontStyle: "italic", marginRight: 10}}>{t('Status')}: </span>{gateway.status.status}
                </Typography>
                <Typography variant="subtitle2">
                    <span style={{
                        fontStyle: "italic", marginRight: 10
                    }}>{t('FW Version')}: </span>{gateway.mgb_version || "N/A"} {StatusMessages[gateway.firmware.status]}
                </Typography>
                <Typography variant="subtitle2">
                    <span style={{
                        fontStyle: "italic", marginRight: 10
                    }}>{t('Last seen')}: </span>{gateway.status.last_seen ? dayjs(gateway.status.last_seen).format('YYYY-MM-DD HH:mm:ss (Z)') : "N/A"}
                </Typography>
            </Paper>
            <Paper elevation={1} sx={{p: 2, width: 0.4}}>
                <Typography fontWeight="bold" sx={{ml: 2}}>{t('Smart Readers stats')}:</Typography>
                <Typography variant="subtitle2">
                    <span style={{
                        fontStyle: "italic", marginRight: 10
                    }}>{t('Online')}: </span>{gateway.statistics.smart_readers.online}
                </Typography>
                <Typography variant="subtitle2">
                    <span style={{
                        fontStyle: "italic", marginRight: 10
                    }}>{t('Offline')}: </span>{gateway.statistics.smart_readers.offline}
                </Typography>
                <Typography variant="subtitle2">
                    <span style={{
                        fontStyle: "italic", marginRight: 10
                    }}>{t('Never Seen')}: </span>{gateway.statistics.smart_readers.never_seen}
                </Typography>
            </Paper>

            <Paper elevation={1} sx={{p: 2, width: 0.3}}>
                <Typography fontWeight="bold" sx={{ml: 2}}>{t('Actions')}:</Typography>
                <Box display="flex" flexDirection="row" width="inherit" sx={{mt: 2}}>
                    <Tooltip title={t("Show Devices")}>
                        <IconButton
                            id="show-gw-readers-button"
                            onClick={showDevices}
                            style={{color: "green"}}
                        >
                            <DevicesIcon/>
                        </IconButton>
                    </Tooltip>
                    {!disableActions && (
                        <>
                            <Tooltip title={t("Edit Gateway")}>
                                <IconButton
                                    id="edit-gw-button"
                                    onClick={() => setIsEditFormOpen(true)}
                                    style={{color: "orange"}}
                                >
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t("UnLink Gateway")}>
                                <IconButton
                                    id="unlink-gw-button"
                                    onClick={unlinkGw}
                                    style={{color: "red"}}
                                >
                                    <LinkOffIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t("Update Firmware")}>
                                <IconButton
                                    disabled={["new", "pending"].includes(gateway.firmware.status)}
                                    id="update-firmware-gw-button"
                                    onClick={() => setUpdateFirmwareFormOpen(true)}
                                    style={{color: ["new", "pending"].includes(gateway.firmware.status) ? "grey" : "blue"}}
                                >
                                    <SystemUpdateAltIcon/>
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                </Box>
            </Paper>


            <GatewayEditForm
                isOpen={isEditFormOpen}
                setIsOpen={setIsEditFormOpen}
                gateway={gateway}
            />

            <GatewayUpdateFirmwareForm
                isOpen={isUpdateFirmwareFormOpen}
                setIsOpen={setUpdateFirmwareFormOpen}
                gateway={gateway}
            />


        </Box>
    </>);
};

export default GatewayManagementDetails;
