import React from 'react';

import OrganizationManagement from './OrganizationManagement/OrganizationManagement';
import {useQuery} from '@tanstack/react-query';
import {API_URLS, QUERY_KEYS} from '../../consts/consts';
import {callApiV2} from '../../services/server_apiV2';
import {useTranslation} from "react-i18next";
import CreateOrganizationModal from "./OrganizationManagement/CreateOrganizationModal";
import {useAuth} from "../../hooks/AuthProvider";
import {useNavigate} from "react-router-dom";
import {USER_HOME_ROUTES} from "../../consts/routes";


const OrganizationsConsole = () => {
    const {t} = useTranslation();
    const {currentUser} = useAuth();
    const navigate = useNavigate();
    const orgQuery = useQuery({
        queryKey: [QUERY_KEYS.ORGANIZATIONS],
        queryFn: async () =>
            await callApiV2({url: API_URLS.ORGANIZATIONS}).then((res) =>
                res.success ? res.data : Promise.reject(new Error(res.data))
            )
    });

    if (orgQuery.isPending || !orgQuery.data) {
        return <div>No data</div>
    }

    if (['utility_tech', 'wm', 'manufacturer'].includes(currentUser.role)) {
        USER_HOME_ROUTES[currentUser.role] && navigate(USER_HOME_ROUTES[currentUser.role]);
        return (
            <div>
                <div>You don't have access to this page.</div>
            </div>
        )
    }

    return (
        <div style={{display: "flex", flexDirection: "column", gap: 0}}>
            {['superuser', 'wm', "manufacturer"].includes(currentUser.role) && (
                <CreateOrganizationModal />
            )}
            <OrganizationManagement
                orgs={orgQuery.data}
                showOrgUsers={currentUser.role === 'superuser'}
                disableActions={['helpdesk'].includes(currentUser.role)}
            />
        </div>
    );
};

export default OrganizationsConsole;
