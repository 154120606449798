import React, {useState} from 'react';
import styles from './Login.module.css';
import {useAuth} from 'hooks/AuthProvider';
import {Link, useNavigate} from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {notificationError, notificationSuccess} from 'utils/utils';
import {useTranslation} from "react-i18next";
import {API_URLS} from "../../consts/consts";
import i18n from "../../i18n";
import {ROUTES} from "../../consts/routes";

const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};


const ForgotPasswordRequest = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [input, setInput] = useState({
        email: '',
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateEmail(input.email)) {
            notificationError(t('Provide a valid email address'));
            return;
        }

        const response = await fetch(API_URLS.USERS + 'password_reset_request/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': i18n.resolvedLanguage,
            },
            body: JSON.stringify({email: input.email})
        });
        if (response.status === 201) {
            notificationSuccess(t('Your password reset request accepted. Check your mail, please'));
            navigate(ROUTES.LOGIN);
        } else {
            notificationError(response.data);
        }

    };

    const handleInput = (e) => {
        const {name, value} = e.target;
        setInput((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    return (
        <div className={styles.centeredContainer}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t('Forgot Password')}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={t("Email")}
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={handleInput}
                        />
                        <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
                            {t('Reset password')}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link to={"/login"}>
                                    {t('Sign in')}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </div>
    );
};

export default ForgotPasswordRequest;
