import React, {useState, useRef, useEffect} from 'react';

import {useQuery} from '@tanstack/react-query';
import {API_URLS, QUERY_KEYS} from '../../consts/consts';
import {callApiV2} from '../../services/server_apiV2';
import {useTranslation} from "react-i18next";
import {useAuth} from "../../hooks/AuthProvider";

import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Paper from "@mui/material/Paper";
import CircularProgress from '@mui/material/CircularProgress';

import styles from "./Notifications.module.css"

import NotificationsHeader from "./NotificationsHeader";
import NotificationMessage from "./NotificationMessage";
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";


const Notifications = () => {
    const {t} = useTranslation();
    const {currentUser} = useAuth();

    const [open, setOpen] = useState(false);
    const containerRef = useRef(null);
    const buttonRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (containerRef.current && !containerRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
                setOpen(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [containerRef, buttonRef])

    const notificationsQuery = useQuery({
        queryKey: [QUERY_KEYS.NOTIFICATIONS],
        queryFn: async () =>
            await callApiV2({url: API_URLS.NOTIFICATIONS}).then((res) =>
                res.success ? res.data : Promise.reject(new Error(res.data))
            )
    });

    const unreadedNotificationsCount = notificationsQuery.data?.filter(notiff => notiff.read === false).length

    return (
        <>
            <Tooltip title="Notifications">
                <IconButton
                    ref={buttonRef}
                    color="primary"
                    id="notifications-button"
                    onClick={() => setOpen(prev => !prev)}
                >
                    <Badge color="secondary" badgeContent={unreadedNotificationsCount}>
                        <NotificationsNoneIcon/>
                    </Badge>
                </IconButton>
            </Tooltip>

            {open && (
                <div className={styles.notificationsContainer} ref={containerRef}>
                    <Paper square={false} elevation={5} className={styles.paperRoot}>
                        <NotificationsHeader/>

                        {notificationsQuery.data.length ? (
                            <Stack direction="column" spacing={2}>
                                {notificationsQuery.data.map(notification => (
                                    <NotificationMessage notification={notification} key={notification.id}/>
                                ))}
                            </Stack>
                        ) : (
                            <Typography align="center">Notification box is empty</Typography>
                        )}
                    </Paper>
                </div>
            )}
        </>
    );
};

export default Notifications;
