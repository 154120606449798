import React, {useMemo, useState} from 'react';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {callApiV2} from '../../../services/server_apiV2';
import {API_URLS, QUERY_KEYS} from '../../../consts/consts';
import GatewayProvisionForm from './GatewayProvisionForm';
import GatewayEditForm from "../../GatewaysConsolePage/Forms/GatewayEditForm";

import AddLinkIcon from '@mui/icons-material/AddLink';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import {notificationSuccess} from "../../../utils/utils";
import LinkingGatewayForm from "./LinkingGatewayForm";
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";
import Button from "@mui/material/Button";
import {useAuth} from "../../../hooks/AuthProvider";
import EditIcon from "@mui/icons-material/Edit";
import SearchBar from "@mkyy/mui-search-bar";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import _ from "lodash";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";


// Should be moved to separate component
const SortableTableCell = ({label, field, order, orderBy, onSort}) => {
    return (
        <TableCell>
            <TableSortLabel
                active={field === orderBy}
                direction={orderBy === field ? order : 'asc'}
                onClick={() => onSort && onSort(field)}
            >
                {label}
            </TableSortLabel>
        </TableCell>
    )
}

function descendingComparator(a, b, orderBy) {
    if (_.get(b, orderBy, 0) < _.get(a, orderBy, 0)) {
        return -1;
    }
    if (_.get(b, orderBy, 0) > _.get(a, orderBy, 0)) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


const UnlinkedGWTable = () => {
    const {t, i18n} = useTranslation();
    const {currentUser} = useAuth();
    const queryClient = useQueryClient();
    const [searchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState(searchParams ? searchParams.get('search') : '');
    const [isProvisionFormOpen, setIsProvisionFormOpen] = useState(false);
    const [isLinkFormOpen, setIsLinkFormOpen] = useState(false);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState(null);

    const [displayGwEditPopUp, setDisplayGwEditPopUp] = useState(undefined);

    const {data: gatewaysObjects, isLoading: isGatewaysLoading} = useQuery({
        queryKey: [QUERY_KEYS.GATEWAYS_UNLINKED],
        queryFn: async () =>
            await callApiV2({url: API_URLS.GATEWAYS, params: {is_linked: 'False'}}).then((res) =>
                res.success ? res.data : Promise.reject(new Error(res.data))
            )
    });

    const handleRemoveGW = (id) => {
        if (confirm(t(`Are you sure? (id: {{id}})`, {id: id}))) {
            callApiV2({url: `${API_URLS.GATEWAYS}${id}/`, method: "DELETE"}).then(res => {
                if (res.success) {
                    notificationSuccess(t(`Gateway (id: {{id}}) successfully removed`, {id: id}))
                    queryClient.invalidateQueries({queryKey: [QUERY_KEYS.GATEWAYS_UNLINKED]});
                }
            })
        }
    }

    // Table helpers
    function flattenObject(obj, prefix = '') {
        let result = {};
        for (const key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                Object.assign(result, flattenObject(obj[key], `${prefix}${key}.`));
            } else {
                result[`${prefix}${key}`] = obj[key];
            }
        }
        return result;
    }

    const onSort = (field) => {
        const isAsc = orderBy === field && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(field);
    }

    const filteredGWObjects = useMemo(
        () => gatewaysObjects
            ?.filter(item => {
                if (!searchQuery) return true;

                const flatItem = flattenObject(item);
                return Object.values(flatItem).some(value =>
                    value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
                );
            })
            .sort(getComparator(order, orderBy)),
        [gatewaysObjects, searchQuery, order, orderBy]
    )

    return (
        <>
            <Box style={{display: "flex", justifyContent: "space-between", margin: "10px 0px"}}>
                <SearchBar
                    style={{border: '1px solid #1976d2', alignSelf: 'end'}}
                    value={searchQuery}
                    onChange={newValue => setSearchQuery(newValue)}
                    onSearch={() => {
                    }}
                    onCancelResearch={() => setSearchQuery('')}
                />
                {['superuser', 'wm', "manufacturer"].includes(currentUser.role) && (
                    <Button
                        id='register-gw-button'
                        color="secondary"
                        variant="contained"
                        onClick={() => setIsProvisionFormOpen(true)}
                        style={{display: 'block', textTransform: "capitalize"}}
                    >
                        {t("Register Gateway")}
                    </Button>
                )}
            </Box>

            <TableContainer sx={{mt: 2}} component={Paper}>
                <Table aria-label="collapsible table" size={"small"}>
                    <TableHead>
                        <TableRow>
                            <SortableTableCell label="Gateway ID" field={"id"} onSort={onSort} order={order}
                                               orderBy={orderBy}/>
                            <SortableTableCell label="Organization" field={"organization.name"} onSort={onSort}
                                               order={order}
                                               orderBy={orderBy}/>
                            {!['helpdesk'].includes(currentUser.role) && <TableCell>Actions</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredGWObjects?.length ? filteredGWObjects.map((row) => (
                            <React.Fragment key={row.eui}>
                                <TableRow>
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{row.organization?.name}</TableCell>
                                    {!['helpdesk'].includes(currentUser.role) && (
                                        <TableCell>
                                            <>
                                                {!["manufacturer"].includes(currentUser.role) && (
                                                    <Tooltip title={t("Link Gateway")}>
                                                        <IconButton
                                                            id='link-gw-button'
                                                            disabled={!row.organization}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setIsLinkFormOpen(row)
                                                            }}
                                                        >
                                                            <AddLinkIcon
                                                                style={{color: row.organization ? "green" : "grey"}}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                <Tooltip title={t("Edit Gateway")}>
                                                    <IconButton
                                                        id='edit-gw-button'
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setDisplayGwEditPopUp(row);
                                                        }}
                                                    >
                                                        <EditIcon style={{color: "orange"}}/>
                                                    </IconButton>
                                                </Tooltip>
                                                {!['utility_tech'].includes(currentUser.role) && (
                                                    <Tooltip title={t("Remove Gateway")}>
                                                        <IconButton
                                                            id='remove-gw-button'
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleRemoveGW(row.id)
                                                            }}
                                                        >
                                                            <DeleteIcon style={{color: "red"}}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </>
                                        </TableCell>
                                    )}
                                </TableRow>
                            </React.Fragment>
                        )) : (
                            <TableRow>
                                <TableCell style={{paddingBottom: 30, paddingTop: 30}}
                                           colSpan={3} align="center">
                                    No results found.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <GatewayProvisionForm
                open={isProvisionFormOpen}
                openSetter={setIsProvisionFormOpen}
            />

            {Boolean(displayGwEditPopUp) &&
                <GatewayEditForm
                    isOpen={Boolean(displayGwEditPopUp)}
                    setIsOpen={() => setDisplayGwEditPopUp(undefined)}
                    gateway={displayGwEditPopUp}
                />
            }

            <LinkingGatewayForm
                open={Boolean(isLinkFormOpen)}
                openSetter={setIsLinkFormOpen}
                gateway={isLinkFormOpen}
            />
        </>
    )
}

export default UnlinkedGWTable
