import React from "react";

import {useQueryClient} from '@tanstack/react-query';
import {useTranslation} from "react-i18next";

import {API_URLS, QUERY_KEYS} from '../../../consts/consts';
import {notificationSuccess} from '../../../utils/utils';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import {callApiV2} from "../../../services/server_apiV2";

import {Form, Formik} from 'formik';
import {FormikTextField} from '../../../components/formikFields/FormikTextField'
import * as Yup from 'yup';


const CreateOrganizationModal = ({initialOrg, handleCloseCallBack}) => {
    const {t} = useTranslation()
    const queryClient = useQueryClient();
    const [open, setOpen] = React.useState(Boolean(initialOrg));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleSubmit = (values) => {
        if (initialOrg?.slug) {
            return callApiV2({
                url: API_URLS.ORGANIZATIONS + `${initialOrg.slug}/`,
                method: 'patch',
                data: values
            }).then((res) => {
                if (res.success) {
                    notificationSuccess(t(`Organization ({{name}}) successfully edited`, {name: values.name}));
                    queryClient.invalidateQueries({queryKey: [QUERY_KEYS.ORGANIZATIONS]});
                    queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SMART_READERS_LINKED]});
                    queryClient.invalidateQueries({queryKey: [QUERY_KEYS.GATEWAYS_LINKED]});
                    handleClose();
                }
                return res;
            });
        }

        // Create Organization
        return callApiV2({
            url: API_URLS.ORGANIZATIONS,
            method: 'post',
            data: values
        }).then((res) => {
            if (res.success) {
                notificationSuccess(t(`Organization ({{name}}) successfully created`, {name: values.name}));
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.ORGANIZATIONS]});
                handleClose();
            }
            return res;
        });
    };

    const handleClose = () => {
        setOpen(false);
        handleCloseCallBack && handleCloseCallBack();
    };

    return (
        <>
            {!initialOrg && (
                <Button
                    id="create-org-button"
                    color="secondary"
                    variant="contained"
                    onClick={handleClickOpen}
                    style={{margin: '0 0 0 auto', display: 'block', textTransform: "capitalize"}}
                >
                    {t("Create organization")}
                </Button>
            )}
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle>{initialOrg ? "Edit Organization" : "Create Organization"}</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={{name: initialOrg?.name || ''}}
                        validationSchema={Yup.object({
                            name: Yup.string().required('Required')
                        })}
                        onSubmit={handleSubmit}
                    >
                        <Form>
                            <FormikTextField type="text" label="Organization name" name="name"/>

                            <DialogActions sx={{p: 0}}>
                                <Button
                                    id="create-org-cancel-button"
                                    onClick={handleClose}
                                    disableElevation
                                    variant="contained"
                                    color="warning"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    id="create-org-save-button"
                                    type="submit"
                                    disableElevation
                                    variant="contained"
                                    color="secondary"
                                >
                                    {initialOrg ? "Save" : "Create"}
                                </Button>
                            </DialogActions>
                        </Form>
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default CreateOrganizationModal;
