import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import {DataGrid} from "@mui/x-data-grid";
import {deDE} from "@mui/x-data-grid/locales";
import {formatDateTime, getMeterTypeNotation, roundFloat} from "../../../utils/utils";
import React from "react";


export const MeasurementsTableData = ({smartReader, measurements}) => {
    const { t, i18n} = useTranslation()

    return (
        <div>
            <Box sx={{height: 400, width: '100%'}}>
                <DataGrid
                    disableColumnMenu={true}
                    disableRowSelectionOnClick={true}
                    getRowHeight={() => '1rem'}
                    localeText={i18n.resolvedLanguage === 'de' ? deDE.components.MuiDataGrid.defaultProps.localeText : undefined}
                    rows={measurements.map((measurement, id) => {
                        const typeNotation = getMeterTypeNotation(smartReader.meter.meter_type)
                        return {
                            id: id,
                            time: formatDateTime(measurement.time),
                            current_consumption: roundFloat(measurement.current_consumption / 1000) + ' ' + typeNotation,
                            current_production: roundFloat(measurement.current_production / 1000) + ' ' + typeNotation,
                            total_consumption: roundFloat(measurement.total_consumption / 1000) + ' ' + typeNotation,
                            total_production: roundFloat(measurement.total_production / 1000) + ' ' + typeNotation,
                        }
                    })}
                    columns={[
                        {
                            field: 'time',
                            headerName: t('Timestamp'),
                            flex: 1,
                            height: 'auto',
                            sortable: false
                        },
                        {
                            field: 'current_consumption',
                            headerName: t('Current Consumption'),
                            flex: 1,
                            height: 'auto',
                            sortable: false
                        },
                        {
                            field: 'current_production',
                            headerName: t('Current Production'),
                            flex: 1,
                            height: 'auto',
                            sortable: false
                        },
                        {
                            field: 'total_consumption',
                            headerName: t('Total Consumption'),
                            flex: 1,
                            height: 'auto',
                            sortable: false
                        },
                        {
                            field: 'total_production',
                            headerName: t('Total Production'),
                            flex: 1,
                            height: 'auto',
                            sortable: false
                        },
                    ]}
                    initialState={{
                        pagination: {
                            paginationModel: {page: 0, pageSize: 5}
                        }
                    }}
                    pageSizeOptions={[5, 10, 50]}
                />
            </Box>
        </div>
    );
};