import React from 'react';
import styles from './LoadingScreen.module.css';
import Logo from 'assets/img/logo.png';

const LoadingScreen = () => {
    return (
        <div className={styles.loadingBlock}>
            <img src={Logo} alt="" className={styles.flip}/>
        </div>
    );
};

export default LoadingScreen;
