import React from 'react'
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import {format, formatDistanceToNow, parseISO} from 'date-fns';


import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';

const STATUS_ICON_MAP = {
    1: <CloudDoneIcon style={{color: "green"}} />,  // success
    2: <CloudDownloadIcon style={{color: "blue"}} />,  // pending
    3: <CloudQueueIcon style={{color: "red"}} />,  // error
}

const SmartReaderFirmwareStatus = ({smart_reader}) => {
    const ota_status = smart_reader.ota_status;

    const get_title = (status, ts) => {
        if (!ts) return "Last update: N/A"

        const parsedDate = parseISO(ts);

        if (status === 1) {
            return `Firmware updated ${formatDistanceToNow(parsedDate, { addSuffix: true })}`
        }
        if (status === 3) {
            return `Firmware update failed ${formatDistanceToNow(parsedDate, { addSuffix: true })}`
        }
        if (status === 2) {
            return `Updates within ${formatDistanceToNow(parsedDate)}`
        }
        return `Last update: ${parsedDate ? format(parsedDate, 'yyyy-MM-dd HH:mm:ss') : "N/A"}`
    }

    return (
        <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{timeout: 400}}
            title={get_title(ota_status.status, ota_status.timestamp)}
            placement="top"
        >
            <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
                {STATUS_ICON_MAP[ota_status.status]}
                {smart_reader.desired_fw_version === "N/A" || smart_reader.fw_version === smart_reader.desired_fw_version
                                        ? smart_reader.fw_version : `${smart_reader.fw_version} --> ${smart_reader.desired_fw_version}`}
            </div>
        </Tooltip>
    )
}

export default SmartReaderFirmwareStatus
