import React, {useEffect, useRef, useState} from 'react';
import {notificationError, notificationSuccess} from '../../../utils/utils';
import {useTranslation} from "react-i18next";
import {callApiV2} from "../../../services/server_apiV2";
import {API_URLS, QUERY_KEYS} from "../../../consts/consts";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import Typography from "@mui/material/Typography";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import Paper from "@mui/material/Paper";


const SmartReaderEditForm = ({isOpen, setIsOpen, smartReader}) => {
    const {t} = useTranslation();
    const queryClient = useQueryClient();
    const photoUploadInputRef = useRef(null);
    const [photo, setPhoto] = useState(null)

    const SRPhotoQuery = useQuery({
        queryKey: [QUERY_KEYS.SMART_READERS_PHOTO, smartReader.eui],
        queryFn: async () =>
            await callApiV2({url: `${API_URLS.SMART_READERS_PHOTO}${smartReader.eui}`}).then((res) =>
                res.success ? res.data : Promise.reject(new Error(res.data))
            ),
        retry: false,
        enabled: Boolean(smartReader.eui),
        gcTime: 0,
    });

    useEffect(() => {
        setPhoto(SRPhotoQuery.data?.photo ?? null)
    }, [SRPhotoQuery.data])

    const handlePhotoUpload = (event) => {
        const formData = new FormData();
        formData.append('photo', photo);
        formData.append('smart_reader', smartReader.eui);

        callApiV2({
            url: API_URLS.SMART_READERS_PHOTO,
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(
            (res) => {
                if (!res.success) {
                    notificationError(t(res.short_message, {status: res.status}), res.response);
                    return;
                }
                notificationSuccess(t("Smart Reader photo successfully added."));
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SMART_READERS_PHOTO, smartReader.eui]});

                setIsOpen(false);
            }
        );
    };

    const handlePhotoOnChange = (event) => {
        const file = event.target.files[0];

        if (!file) {
            setPhoto(null);
            return;
        }
        if (!file.type.startsWith('image/')) {
            notificationError(t("Choose correct image file"));
            return;
        }

        setPhoto(file);
    }

    return (
        <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            fullWidth={true}
            maxWidth={'md'}
        >
            <DialogTitle>
                {t('Smart Reader Upload Photo')}
                <Typography
                    variant="subtitle2">{t('Please add the photo of installed Smart Reader. You can add it later from SR Cloud Console page.')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <input
                    type="file"
                    ref={photoUploadInputRef}
                    style={{visibility: "hidden", width: 0}}
                    accept="image/*"
                    onChange={handlePhotoOnChange}
                />
                <Button
                    id="upload-photo-activate-sr-button"
                    onClick={() => photoUploadInputRef.current.click()}
                    style={{color: "blue", marginBottom: 2}}
                >
                    Upload Photo <FileUploadIcon/>
                </Button>

                <Paper sx={{p: 1}} style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    {photo ? (
                        <img src={typeof photo === 'string' || photo instanceof String ? photo : URL.createObjectURL(photo)} alt={"SR Photo Image"}/>
                    ) : <div style={{display: "flex", height: 150, alignItems: "center", justifyContent: "center"}}>No image available</div>}

                </Paper>
            </DialogContent>
            <DialogActions>
                <Button
                    id="upload-photo-sr-form-cancel-button"
                    onClick={() => setIsOpen(false)}
                    disableElevation
                    variant="contained"
                    color="warning"
                >
                    {t('Skip')}
                </Button>
                <Button
                    id="upload-photo-sr-form-submit-button"
                    onClick={handlePhotoUpload}
                    disableElevation
                    disabled={!photo || typeof photo === 'string'}
                    variant="contained"
                    color="secondary"
                >
                    {t('Upload Photo')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};


export default SmartReaderEditForm;
