import React, {useState} from 'react'
import {statusIconSrc, capitalize} from 'utils/utils';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';


const ConnectionStatus = ({status}) => {
    return (
        <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{timeout: 400}}
            title={capitalize(status).split('_').join(' ')}
            placement="top"
        >
            <img src={statusIconSrc(status)} style={{height: "1.25rem"}}/>
        </Tooltip>
    )
}

export default ConnectionStatus
