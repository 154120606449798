import React from "react";
import {createRoot} from 'react-dom/client';

import App from './App';
import './i18n';
import {
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements
} from 'react-router-dom';
import AuthProvider from './hooks/AuthProvider';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const router = createBrowserRouter(createRoutesFromElements(<Route path="*" element={<App/>}/>));

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: Infinity,
            retryDelay: 3000,
            retry: 3
        }
    }
});

const root = createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <RouterProvider router={router}/>
            </AuthProvider>
        </QueryClientProvider>
    </React.StrictMode>
);
