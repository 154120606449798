import React from 'react';
import {useTranslation} from "react-i18next";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from '@mui/material/Grid';

import FieldTypography from "../../../components/helpers/FieldTypography";
import {callApiV2} from "../../../services/server_apiV2";
import {API_URLS, QUERY_KEYS} from "../../../consts/consts";
import {notificationError, notificationWarning} from "../../../utils/utils";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {INTERVAL_OPTIONS} from "../../GatewaysConsolePage/Forms/SmartReaderToGatewayLink";


const SmartReaderActivationForm = ({isOpen, setIsOpen, smartReader}) => {
    const {t} = useTranslation();
    const queryClient = useQueryClient();

    const SRPhotoQuery = useQuery({
        queryKey: [QUERY_KEYS.SMART_READERS_PHOTO, smartReader.eui],
        queryFn: async () =>
            await callApiV2({url: `${API_URLS.SMART_READERS_PHOTO}${smartReader.eui}`}).then((res) =>
                res.success ? res.data : Promise.reject(new Error(res.data))
            ),
        retry: false,
        enabled: Boolean(smartReader.eui),
    });

    const handleActivateSmartReader = () => {
        callApiV2({
            url: `${API_URLS.SMART_READERS}${smartReader.eui}/`,
            method: 'PATCH',
            data: {activated: true}
        }).then(
            (res) => {
                if (!res.success) {
                    notificationError(t(res.short_message, {status: res.status}), res.response);
                    return;
                }
                notificationWarning(t("Smart Reader will activate soon"));
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SMART_READERS_LINKED]});
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SMART_READERS_UNLINKED]});
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.GATEWAYS_LINKED]});

                setIsOpen(false);
            }
        );
    };

    return (
        <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            fullWidth={true}
            maxWidth={'md'}
        >
            <DialogTitle>
                {t('Smart Reader Approval')}
                <Typography
                    variant="subtitle2">{t('Please carefully check all the information and if you agree, click the Approve button.')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Paper sx={{p: 2, mb: 1}}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FieldTypography label={'Name'} value={smartReader.name}/>
                            <FieldTypography label={'EUI'} value={smartReader.eui}/>
                            <FieldTypography label={'Join EUI'} value={smartReader.eui}/>
                            <FieldTypography label={'App Key'} value={smartReader.app_key}/>
                            <FieldTypography label={'Network Key'} value={smartReader.nwk_key}/>
                        </Grid>
                        <Grid item xs={6}>
                            <FieldTypography label={'Meter ID'} value={smartReader.meter?.id}/>
                            <FieldTypography label={'Meter Type'} value={smartReader.meter?.meter_type}/>
                            <FieldTypography label={'Gateway Name'} value={smartReader.gateway_name}/>
                            <FieldTypography label={'Gateway ID'} value={smartReader.gateway}/>
                            <FieldTypography label={'Address'} value={smartReader.address}/>
                        </Grid>
                        <Grid item xs={6}>
                            <FieldTypography label={'Metering Data via SMGw'} value={smartReader.lmn_mode_enabled ? "Yes" : "No"}/>
                            <FieldTypography label={'Metering Data via Cloud'} value={smartReader.non_lmn_mode_enabled ? "Yes" : "No"}/>
                        </Grid>
                        <Grid item xs={6}>
                            <FieldTypography label={'Metering Interval'} value={INTERVAL_OPTIONS.find(int => int.value === smartReader.lmn_uplink_interval)?.label}/>
                            <FieldTypography label={'Metering Interval'} value={INTERVAL_OPTIONS.find(int => int.value === smartReader.cloud_uplink_interval)?.label}/>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper sx={{p: 1}} style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    {SRPhotoQuery.data ? (
                        <img src={SRPhotoQuery.data.photo} alt={"SR Photo Image"}/>
                    ) : <div style={{display: "flex", height: 150, alignItems: "center", justifyContent: "center"}}>No image available</div>}

                </Paper>
            </DialogContent>

            <DialogActions>
                <Button
                    id="activate-sr-form-cancel-button"
                    onClick={() => setIsOpen(false)}
                    disableElevation
                    variant="contained"
                    color="warning"
                >
                    {t('Close')}
                </Button>
                <Button
                    id="activate-sr-form-submit-button"
                    onClick={handleActivateSmartReader}
                    disabled={!SRPhotoQuery.data}
                    disableElevation
                    variant="contained"
                    color="secondary"
                >
                    {t('Approve')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};


export default SmartReaderActivationForm;
